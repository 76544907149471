import vi from '../config/i18n/vi.json';
//import en from "../config/i18n/en.json";
import no from '../config/i18n/no.json';


import '../index.scss';
// eslint-disable-next-line
import '../scss/styles.scss';
import '../scss/dataGrid.scss';

// LIBS
import EventBus from '../libs/EventBus';
import EventAction from '../libs/EventActions';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// CONFIGS
import { localDB } from '../models/localData';
import { server } from '../config/server';

(function () {
    //define global variable
    function nil() {}
    window.nil = nil;

    const APP = {
        server,
        routerHelper: Object(),
        __EVENTS__: new EventBus(),
        __DATA__: new EventAction()
    };

    window.APP = APP;
}.call(this));

const initI18n = (code = 'vi') => {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                /*en: {
                    translation: en,
                },*/
                vi: {
                    translation: vi
                },
                no: {
                    translation: no
                }
            },
            lng: code, // if you're using a language detector, do not define the lng option
            fallbackLng: 'en',

            interpolation: {
                escapeValue: false
            }
        });
};

const boots = () => {
    initI18n(localDB.myOwn.getKey('Language'));
};

export default boots;
