


const CODE_DATA = {
    ARR_ACC_CODE:{
        list:[ '2511102','1231118','4121107','4121109','4121113','3432101','3432102','4121114','3432135','3432126','4121115','4113108','1231115','1227170','3432114','4121119'],
        desc:`<span contentEditable="true" class="c5 editable"
        >Arbeidstaker er kjent med at foretaket i årsoppgjørsperioden har en
        særskilt arbeidsbelastning. Arbeidstaker må påregne overtid i denne
        perioden.</span>`
    },
    ARR_CAR_CODE:{
        list:['5221103'],
        desc:``
    },
    ARR_SALE_CAR_CODE:{
        list:['9142101'],
        desc:``
    }
}

const getSpecialJobDesc = (code)=>{
    let ret = '';
    Object.keys(CODE_DATA).map((item)=>{
      CODE_DATA[item]['list'].some((item2)=>{
        if(item2 === code){
          ret = CODE_DATA[item]['desc']
        }
      });
    });
    return ret
}

export default getSpecialJobDesc ; 