

/**
 * 
 * @returns {array} list years based on current year
 */
function generateYears(){

    const date = new Date();

    const arr = [];
    const curYear = parseInt(date.getFullYear());
    for(let i= curYear; i > curYear - 7 ; i-- ){
        arr.push(i)
    }

    return arr

}

export default generateYears ; 
