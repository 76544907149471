import SETTINGS from "../config/settings.json";

const {VAT_CODES} = SETTINGS ;
/**
 * LẤY DANH SÁCH CÁC MÃ VAT CODE ĐƯA VÀO SELECT LIST
 * @param {number} group
 * @returns {Array} []
 */
function getListVATCodes(group = 0, SAFTCode = null, AccountingCode=null) {
    let arr = [];

    
    try {
        if (SAFTCode !== null && AccountingCode !== null) {
            const code = SAFTCode.toString().substr(0, 1);

            const arr =  group != "0" ?  VAT_CODES.filter(
                (item) =>
                    item.group === parseInt(group) &&
                    item.SaftRef.includes(code)
            ): VAT_CODES.filter((item)=> item.relatedCode.includes(AccountingCode))

            return arr;
        }
    } catch {}

    return arr;
}

export default getListVATCodes;
