class MyHelper {
    rand(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    /*
    return { params }
    */
    parseUrl(url = '') {
        var regex = /[?&]([^=#]+)=([^&#]*)/g,
            url,
            params = {},
            match;
        while ((match = regex.exec(url))) {
            params[match[1]] = match[2];
        }

        return params;
    }
    subArr(string, count) {
        let str = '';

        // abc dcg sdsd
        if (string !== '') {
            const formatString = string.replace(/<\/?[^>]+(>|$)/g, '');

            const myStr = formatString.split(' ');
            for (let i = 0; i < myStr.length; i++) {
                if (count > i) {
                    str += myStr[i] + ' ';
                }
            }
        }

        return str + '...';
    }

    strip(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }
    clearFieldNull(list) {
        Object.keys(list).map((item) => {
            if (list[item] === 'null') {
                delete list[item];
            }
        });

        return list;
    }
    covertJsonFieldToString(list) {
        Object.keys(list).map((key) => {
            if (typeof list[key] === 'object') {
                list[key] = JSON.stringify(list[key]);
            }
        });

        return list;
    }

    trim(str, charlist) {
        if (typeof charlist == 'undefined') {
            charlist = '\\s';
        }

        var pattern = '^[' + charlist + ']*(.*?)[' + charlist + ']*$';

        return str.replace(new RegExp(pattern), '$1');
    }

    doSlug(str) {
        //str = str.trim();
        let newStr = this.khongdau(str).replace(/ /g, '-');
        newStr = newStr.replace(/–/g, '');
        newStr = newStr.substr(0, newStr.length - 1);

        newStr = newStr.replace(/--/g, '-');

        return newStr;
    }
    khongdau(str) {
        str = str.trim();
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g, ' ');
        /* tìm và thay thế các kí tự đặc biệt trong chuỗi sang kí tự - */
        str = str.replace(/-+-/g, '-'); //thay thế 2- thành 1-
        str = str.replace(/^\-+|\-+$/g, '');
        //cắt bỏ ký tự - ở đầu và cuối chuỗi
        return str;
    }

    /* fuction trả về phần tử trùng nhau */
    arr_intersection(arr1, arr2) {
        return arr1.filter((x) => arr2.includes(x));
    }

    /* tra về phần tử khác nhau : của arra1 */
    arr_diff(arr1, arr2) {
        return arr1.filter((x) => !arr2.includes(x));
    }

    /* function : compare : schema fields table default must have [] */
    isPassedSchema(schema, data) {
        let ret = '';
        const filters = this.arr_diff(schema, data);

        if (filters.length > 0) {
            filters.forEach((item) => {
                ret += item + ', ';
            });
        }
        return ret;
    }

    validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    calculateDate(d1, d2) {
        d1 = new Date(d1);
        d2 = new Date(d2);

        const diff = Math.floor(d2.getTime() - d1.getTime());
        const day = 1000 * 60 * 60 * 24;

        const days = Math.floor(diff / day);
        const months = Math.floor(days / 31);
        const years = Math.floor(months / 12);

        return {
            days,
            months,
            years
        };

        return days;
    }
    /**
     *
     * @param {string} birthDay : yyyy-MM-dd
     * @returns {number}
     */
    calculateAge(birthDay) {
        try {
            const date = new Date();
            const dx = birthDay.replace(birthDay.substr(0, 4), date.getFullYear());

            const isOverBirthDay = this.calculateDate(new Date(), dx).days < 0;

            const ret = this.calculateDate(birthDay, new Date());

            return isOverBirthDay ? ret.years + 1 : ret.years;
        } catch (err) {}
    }

    arrInsertAt(arr, index, value) {
        return arr.splice(index, 0, value);
    }

    /**
     *
     * @param {string} URL
     * @returns {object} { param1, param2,..}
     */
    getUrlParams(URL = '') {
        try {
            const test = URL.split('?')[1].split('&');

            let obj = {};
            test.forEach((item) => {
                const a = item.split('=');
                obj[a[0]] = a[1];
            });

            return obj;
        } catch (err) {
            return;
        }
    }

    /**
     *
     * @param {string} str 123456789
     * @returns {string} ret ; 123.456.789
     */
    formatOrgId(str = '') {
        if (str.length < 9) {
            return;
        }

        let ret = '';
        str.split('').forEach((item, index) => {
            let dot = index === 2 || index === 5 ? ' ' : '';

            ret += item + dot;
        });

        return ret;
    }

    formatPersonNo(str = '') {
        if (str.length < 9) {
            return;
        }

        let ret = '';
        str.split('').forEach((item, index) => {
            let dot = index === 1 || index === 5 ? '.' : '';
            dot = index === 3 ? '.19' : dot;

            ret += item + dot;
        });

        return ret.substr(0, 10);
    }

    /**
     *
     * @returns { array } LIST OF TIMER 00:00, 00:30 ...
     */
    getListTime() {
        const max = 48;
        let arr = [];
        for (let i = 0; i < max; i++) {
            const test = (i / 2).toString();
            const formatText = (str) => {
                return parseInt(str) < 10 ? '0' + str : str;
            };

            const hh = test.indexOf('.') > -1 ? formatText(parseInt(test) < 10 ? test.substr(0, 1) : test.substr(0, 2)) : formatText(test);
            const mm = test.indexOf('.') > -1 ? '30' : '00';
            arr.push({
                code: hh + ':' + mm,
                value: hh + ':' + mm
            });
        }

        return arr;
    }
}

export const Helper = new MyHelper();
