import axios from 'axios';
import { localDB } from './localData';

import getAllCompanyBase from '../ultils/getAllCompany';
import { doGetWithBearer } from '../ultils';

const BASE = process.env.NODE_ENV === 'development' ? 'https://api-account.oryton.vn' : 'https://api-account.oryton.no';

const singCompany = (() => {
    let instance;

    function init() {
        let INFO = localDB.getOrytonCompany(); // current Company info
        const DATA = []; // LIST ALL COMPANY HERE

        function getInfo() {
            return INFO;
        }

        /**
         *
         * @returns {boolean}
         */
        function isUsingBilagsky() {
            if (!window.SETTING_INFO) return false;

            return window.SETTING_INFO.BankAccForSalary && window.SETTING_INFO.BankAccForTax;
        }

        function bankAdvanceSetting() {
            const companyInfo = this.getInfo();
            const base = process.env.NODE_ENV === 'development' ? 'https://www.dev.bilagsky.no' : 'https://www.bilagsky.no';
            const URL = base + '/' + companyInfo.Alias + '/setting';

            window.open(URL, '_blank').focus();
        }

        function setInfo(data) {
            INFO = data;

            localDB.saveOrytonCompany(INFO);
        }

        function getFullInfo(compOrgId = 0, companyId = 0) {
            return new Promise((resolve) => {
                compOrgId = compOrgId === '1111111111' || compOrgId === '222222222' ? 989261703 : compOrgId;
                const URL = '/apix/company/getFull?orgId=' + compOrgId + '&id=' + companyId;

                doGetWithBearer(URL).then((res) => {
                    if (res.name === 'success') {
                        const newData = res.data;

                        // THEN SAVE DATA
                        setInfo(newData);
                    } else {
                        console.log(res);
                    }
                });
            });
        }

        function getAllCompany() {
            return new Promise((resolve, reject) => {
                getAllCompanyBase().then((resData) => {
                    resolve(resData);
                });
            });
        }

        function getInfoByBrreg(orgID = 0) {
            const type = {
                isSuccess: true,
                data: {}
            };
            return new Promise((resolve) => {
                const URL = BASE + '/api/3rd/brreg/company/' + orgID;
                axios
                    .get(URL)
                    .then((res) => {
                        const { data } = res;
                        resolve(data);
                    })
                    .catch((err) => {
                        resolve({ ...type, msg: err });
                    });
            });
        }

        function getBasicInfo(id = 0) {
            const type = {
                isSuccess: true,
                data: {}
            };

            return new Promise((resolve) => {
                const URL = BASE + '/ajax/companies/' + id;
                axios
                    .get(URL)
                    .then((res) => {
                        const { data } = res;

                        resolve(data);
                    })
                    .catch((err) => {
                        resolve({ ...type, msg: err });
                    });
            });
        }

        function checkExisted(orgID = 0) {
            let type = false;

            return new Promise((resolve) => {
                const URL = BASE + '/ajax/companies/isexisted/' + orgID;
                axios
                    .get(URL)
                    .then((res) => {
                        const { data } = res;
                        type = data.isSuccess;

                        resolve(type);
                    })
                    .catch((err) => {
                        resolve(false);
                    });
            });
        }

        function listByBrreg(name = '') {
            const type = {
                isSuccess: true,
                data: {}
            };

            return new Promise((resolve) => {
                const URL = BASE + '/api/3rd/brreg/list/' + name;
                axios
                    .get(URL)
                    .then((res) => {
                        const { data } = res;
                        resolve(data);
                    })
                    .catch((err) => {
                        resolve({ ...type, msg: err });
                    });
            });
        }

        function listAll() {
            const type = {
                isSuccess: false,
                data: []
            };

            return new Promise((resolve) => {
                if (this.data.length === 0) {
                    const URL = BASE + '/ajax/companies/getAll';
                    axios
                        .get(URL)
                        .then((res) => {
                            const { data } = res;

                            resolve(data);
                        })
                        .catch((err) => {
                            resolve({ ...type, msg: err });
                        });
                } else {
                    resolve({ ...type, isSuccess: true, data: this.data });
                }
            });
        }

        return {
            getInfo,
            setInfo,
            getFullInfo,
            getAllCompany,
            getInfoByBrreg,
            getBasicInfo,
            checkExisted,
            listByBrreg,
            listAll,
            isUsingBilagsky,
            bankAdvanceSetting
        };
    }

    return {
        start() {
            if (!instance) {
                instance = new init();
            }

            return instance;
        }
    };
})().start();

export default singCompany;
