import doGet from "./doGet";

// RE-FORMAT FOR SELECT COMPONENT USE
const myType = [{
    id: 0,
    value: 0,
    label: "",
}];
function getAllCompany() {
    return new Promise((resolve, reject) => {
        const uri = `https://api.oryton.vn/ajax/companies/getAll`;
        doGet(uri, false).then((res) => {
            if (res.status === "success") {
                const { data } = res;
                // FORMAT FOR SELECT OPTION
                const newOptions = [...myType];
                data.map((item) => {
                    newOptions.push({
                        id: item.Id,
                        value: item.OrganisationNumber,
                        label: item.CompanyName,
                    });
                });


                resolve(newOptions);

                
            }
        });
    });
}

export default getAllCompany;
