import { singUser } from './models/singUser';
import { Helper } from './ultils/help';
import { server } from './config/server';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// PAGES
import { Page404 } from './pages/Page404';
import { Page500 } from './pages/Page500';
import { DefaultLayout } from './layout';

function App() {
    const [state, setState] = useState({ isLogin: false });

    useEffect(() => {
        let mounted = true;

        (async () => {
            const isLogin = await singUser.checkLogIn();

            if (mounted) {
                if (!isLogin) {
                    const params = Helper.getUrlParams(window.location.href);

                    if (params && params.token) {
                        singUser.loginByToken(params.token);
                    } else {
                        window.location.href = server.ORYTON_API + '/login?redirect-url=' + window.location.href;
                    }
                }

                setState({ isLogin });
            }
        })();

        return () => (mounted = false);
    }, []);

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/404" component={Page404} />
                <Route path="/500" component={Page500} />

                <Route path="/:company/:page" component={state.isLogin ? DefaultLayout : null} />

                <Route path="/" component={state.isLogin ? DefaultLayout : null} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
