import { server } from '../config/server';
import axios from 'axios';

import { preLoad } from '../hooks/before';
const RES_TYPE = {
    name: 'hook-error',
    message: ''
};
const doCreatePdf = (postData) => {
    return new Promise((resolve) => {
        const uri = server.PDF_SERVER + '/apix/media/create-pdf';

        preLoad('loading');
        axios.post(uri, postData).then((resData) => {
            const { data } = resData;
            resolve({
                ...RES_TYPE,
                ...data
            });

            preLoad('stop');
        });
    });
};

export default doCreatePdf;
