
export class AbtractHandler {

    constructor(name='none'){
 
        this._name = name ; 

        // MODEL 
        this.model = null; 
        this.MODEL = {}
        
        // VIEW 
        this.stateHelper = null ;  
        this.stateCatcher = null;  // using for reaction error
        
    }
    
    /** SHOULD OVERWRITE */
    _load(){}

    // CONNECT VIEW FOR CATCHER ERR
    setCatcher(stateCatcher){
        this.stateCatcher = stateCatcher
    }
    // CONNECT VIEW 
    setHelper(stateHelper){
        this.stateHelper = stateHelper
    }
    setModel(model){
        this.model = model
    }

    addModel(model){
        this.MODEL[model._name] = model;
    }
    // NEED OVERWRITE
    boot(){
        alert("Need overwrite this function")
    }

}