

export class MyStateHelper {

    
    constructor(state=null, setState=null){
        this.state = state;
        this.setState = setState;

        this.ctrl = null ;
        
    }

    set(state, setState=Object){
        this.state = state;
        this.setState = setState
    }
    
    toggleModal(keyModal, props = {}) {
        this.setState((prev) => {
            return {
                ...prev,
                [keyModal]: !prev[keyModal],
                ...props,
            };
        });
    }

    
    whereStateChangeArr(key, value){
        this.setState(prev=>{
            return {
                ...prev,
                [key]:[...prev[key], value]
            }
        })
    }
    whereStateChange(props, value) {
        props = typeof props === "string" ? { [props]: value } : props;
        
        this.setState((prev) => {
            return {
                ...prev,
                ...props,
            };
        });
    }

    inCrease(field){

        this.setState((prev)=>{
            return {
                ...prev,
                [field]: prev[field] + 1
            }
        })
    }

    connectCtrl(ctrl){
       
        this.ctrl = ctrl; 
        this.ctrl.setHelper(this);
    }


}

