/**
 * 
 * @param {string} fullName 
 * @returns {boolean} 
 */
const validFullName = (fullName='')=>{
    let ret = fullName.split(' ').length < 2 ? false : true ; 
  if(ret){
     ret = fullName.split(' ')[0].length < 2 ? false : true ;

     if(ret){
         ret = fullName.split(' ')[1].length < 2 ? false : true
     }
  }
  return ret
}

export default validFullName 
