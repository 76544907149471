import { localDB } from '../models/localData';

/**
 *
 * @param {string} code
 * @returns {json} || null
 */
function getAccountCodeInfo(code) {
    try {
        code = code.indexOf(':') > -1 ? code.split(':')[0] : code;

        return localDB.retrieving('ACCOUNT_CODES').find((item) => item.Code === code);
    } catch {
        return null;
    }
}

export default getAccountCodeInfo;
