
import SETTINGS from "../config/settings.json";

const {VAT_CODES} = SETTINGS ;
const getRealVATCode = (AccountingCode=null)=>{

    if(!AccountingCode)
        return 

    
    return VAT_CODES.find(item=>item.Code === AccountingCode.toString()).VatCode ; 
    
    
    
}

export default getRealVATCode ; 
