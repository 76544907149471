import axios from "axios";


/**
 * 
 * @param {string} q  
 * @returns Array
 */
function geoSearch(q='', country='') {
    


    return new Promise((resolve, reject) => {
        
        q+=', '+country;
        const url = `https://geocode.search.hereapi.com/v1/geocode?q=${q}&apiKey=Fi6pIBnCwoqRw_vgyNZdJAICtLJYB8qZzds8ppSGykE`;

        axios.get(url).then((res) => {
            
            resolve(res.data.items);
        })
        .catch((err)=>{
            reject(err)
        })
    });
}

export default geoSearch;
