/**
 * USES SINGLE PATTERN MAKE SURE ONLY ONE INSTANCE USED
 */

class EventBus {
    _instance = null;
    _EVENTS = new Map();
    constructor() {
        if (!EventBus._instance) {
            EventBus._instance = this;
        }

        return EventBus._instance;
    }

    listen(eventName = "", callback = (data) => {}) {
        if (!this._EVENTS.has(eventName)) {
            this._EVENTS.set(eventName, []);
        }

        const arr = this._EVENTS.get(eventName);
        arr.push(callback);
        this._EVENTS.set(eventName, arr);
    }

    dispatch(eventName = "", dataObject = {}) {
        if (
            !this._EVENTS.has(eventName) ||
            this._EVENTS.get(eventName).size < 1
        )
            return;

        this._EVENTS.get(eventName).forEach((listener) => {
            listener(dataObject);
        });
    }

    destroy(eventName = '') {
        this._EVENTS.delete(eventName);
    }
}

export default EventBus;
