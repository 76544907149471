import { MdDehaze } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import getCurrentRoute from '../../ultils/getCurrentRoute';

export const HeaderLeftSide = ({ _toggleLeftSide = () => {} }) => {
    const { t } = useTranslation();

    const routeInfo = useMemo(() => {
        return getCurrentRoute();
    }, [getCurrentRoute()]);

    return routeInfo ? (
        <ul className="navbar-list left">
            <li className="display-flex nowrap">
                <button
                    onClick={_toggleLeftSide}
                    style={{
                        background: 'none',
                        border: 0,
                        lineHeight: '74px',
                        height: 63,
                        position: 'absolute',
                        display: '-webkit-flex',
                        alignItems: 'center'
                    }}
                >
                    <MdDehaze size={20} />
                </button>
                <label
                    id="app-title"
                    className="breadcrumb hide-on-small-only"
                    style={{
                        marginLeft: 45,
                        fontSize: 18,
                        fontWeight: '700',
                        color: '#162c41',
                        background: '#fff'
                    }}
                >
                    <span className="Aa">{t(routeInfo.name)}</span>
                </label>
            </li>
        </ul>
    ) : null;
};
HeaderLeftSide.displayName = 'HeaderLeftSide';
