

/**
 * 
 * @param {string} loading
 * @returns side effect 
 */
export default function preLoad(loading){
    
    
    const preload = document.querySelector('.preloader-it-2'); 
    const preloadText = document.querySelector('.preload-text');
    


    switch(loading){

        case 'upload':
            preload.style.display = 'block';
            preloadText.innerHTML = 'Uploading...'; 
        break;
        case 'get':
            preload.style.display = 'block';
            preloadText.innerHTML = 'process Loading...'; 
        
        break ;

        
        case 'authenticate':
            
            preload.style.display = 'block';
            preloadText.innerHTML = 'process authentication...';
        break;
        case 'loading':
            
        try{
            preload.style.display = 'block';
            preloadText.innerHTML = 'Loading...';
            
        }catch(err){}
            

        break;

        case 'stop':
        preload.style.display = 'none';
        break;

        case 'POST':
            preload.style.display = 'block';
            preloadText.innerHTML = 'in progress...';
        break ;

        case 'PUT':
            preload.style.display = 'block';
            preloadText.innerHTML = 'in progress...';
        break ;

        case 'PATCH':
            preload.style.display = 'block';
            preloadText.innerHTML = 'in progress...';
        break ;

        case 'DELETE':
            preload.style.display = 'block';
            preloadText.innerHTML = 'in progress...';
        break ;

        case 'photo':
            preload.style.display = 'block';
            preloadText.innerHTML = 'upload photo...';
        break ;
        
        
        
        
    }    
    

}