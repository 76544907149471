import csc from "country-state-city";


/**
 * 
 * @param {string} code 
 * @returns {string} Country name
 */
const getCountryNameByCode = (code)=>{

    return csc.getAllCountries().find((item)=> item.isoCode === code)['name'] || null ;
    
}

export default getCountryNameByCode ; 