

import axios from 'axios';

function getZipCode(code=''){
 
    return new Promise((resolve, reject)=>{

        const uri = `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=https://www.example.com&pnr=${code}`;
        axios.get(uri).then((res)=>{
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export default getZipCode ;
