import { Helper } from "../ultils";

/**
 * GET 6 DIGI AND RETURN DOB IF VALID AGE
 * @param {string} str 
 * @param {string} preText 
 * @returns {string} DOB
 */
const parseDOB = (str='', preText = "19") => {
    if (str.length < 6) return;
    else {
        try {
            str = str.toString().substr(0, 6);
            const yyyy = parseInt(preText + str.substr(4, 2));
            const dob = yyyy + "-" + str.substr(2, 2) + "-" + str.substr(0, 2);

            const age = Helper.calculateAge(dob);

            if (age <= 100) {
                return dob;
            } else {
                parseDOB(str, "20");
            }
        } catch (err) {}
    } 
};

export default parseDOB;
