import { localDB } from '../models/localData';
import { server } from '../config/server';
import axios from 'axios';

/**
 *
 * @param {string} uri
 * @param {object} data
 * @returns { JSON }
 */

const RES_TYPE = {
    name: 'hook-err',
    message: '',
    data: null
};
function doGetWithBearer(uri = '', token = '') {
    return new Promise((resolve) => {
        if (uri !== '') {
            const url = server.base() + uri;
            const TOKEN = token === '' ? localDB.getOrytonUser()['AccessToken'] : token;

            axios
                .get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + TOKEN,
                        'cache-control': 'no-cache'
                    }
                })
                .then((res) => {
                    const { data } = res;

                    resolve({
                        ...RES_TYPE,
                        ...data
                    });
                })
                .catch((err) => {
                    resolve({
                        ...RES_TYPE,
                        message: err
                    });
                });
        } else {
            resolve({ ...RES_TYPE, msg: 'Missed uri' });
        }
    });
}

export default doGetWithBearer;
