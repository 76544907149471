import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from '../../components/Oryton';
import picNoPermission from '../../assets/no-permission.jpg';

export const InvalidPage = React.memo(({ msg = '' }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                width: 700,
                margin: 'auto',
                marginTop: '5%',
                background: '#fff',
                border: '1px solid rgba(0,0,0,0.1)',
                padding: 15,
                borderRadius: 6,
                textAlign: 'center'
            }}
        >
            <Row>
                <Col col={12}>
                    <p className="text-blue font-20">{msg}</p>
                    <img style={{ height: 300 }} src={picNoPermission} />
                    <p>
                        {t("Hmmm..., We are sorry, but you don't have permission to access this area")} <br />
                        <span>{t('Please contact your Administrator')}</span>
                    </p>
                </Col>
            </Row>
        </div>
    );
});
InvalidPage.displayName = 'InvalidPage';
