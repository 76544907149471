import Box404 from '../../components/Oryton/404Box';

export const Page500 = () => {
    return (
        <div style={{ width: '50wv', margin: 'auto' }}>
            <Box404
                message={
                    <>
                        <h3>Internal Error</h3>
                        <p className="font-20">Its not you, its us</p>
                        <p>
                            <a href="/" className="font-20">
                                Go back{' '}
                            </a>
                        </p>
                    </>
                }
                type={500}
            />
        </div>
    );
};
