
const listPercent = ()=>{

    let arr = [];
    for(let i = 0 ; i<=100; i++){

        arr.push({
            code:i,
            value:i+'%'
        });

        i+=4 ;
        
    }

    return arr;
}

export default listPercent