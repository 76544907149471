/**
 * CONSTANCE FOR LOCAL DB
 * USER_ORYTON : OBJECT : {}
 * USER_SALARY : OBJECT : {}
 * COMPANY_ORYTON : {}
 */

export const localDB = (() => {
    let instace;

    function init() {
        return {
            /* ORYTON ACCOUNT */
            saveOrytonUser(json = {}) {
                this.insert("USER_ORYTON", json);
            },
            getOrytonUser() {
                return this.retrieving("USER_ORYTON");
            },
            removeOrytonUser() {
                return this.remove("USER_ORYTON");
            },
            // END ORYTON ACCOUNT

            // ORYTON COMPANY
            saveOrytonCompany(json = {}) {
                this.insert("COMPANY_ORYTON", json);
            },
            getOrytonCompany() {
                return this.retrieving("COMPANY_ORYTON");
            },
            removeOrytonCompany() {
                return this.remove("COMPANY_ORYTON");
            },
            // END COMPANY

            /**
             *
             * type: voice
             * @param {string} key
             * @param {*} value  // string or object
             */
            insert(key = "", value) {
                value =
                    typeof value === "object" ? JSON.stringify(value) : value;
                window.localStorage.setItem(key, value);
            },

            /**
             *
             * @param {string} key
             * @returns string || object
             *
             */
            retrieving(key = "") {
                let value = window.localStorage.getItem(key) || "";
                value = value.indexOf(`{`) > -1 ? JSON.parse(value) : value;
                return value;
            },
            remove(key = "") {
                return window.localStorage.removeItem(key);
            },
            removeAll() {
                return window.localStorage.clear();
            },

            curCompany: {
                get() {
                    return this.Parent.getOrytonCompany();
                },
            },
            myOwn: {
                get() {
                    return this.Parent.getOrytonUser();
                },
                setKey(key = "", value = "") {
                    const data = this.get();
                    if (data !== "") {
                        data[key] = value;
                        this.Parent.saveOrytonUser(data);
                    }
                },
                getKey(key = "") {
                    const data = this.get();

                    if (data === "") return;

                    return data[key];
                },
            },

            init() {
                this.myOwn.Parent = this;
                this.curCompany.Parent = this;
                delete this.init;
                return this;
            },
        }.init()
    }

    return {
        start() {
            if (!instace) {
                instace = new init();
            }

            return instace;
        },
    };
})().start();


