import axios from 'axios';
import { server } from '../config/server';
import { localDB } from './localData';

// HOW
import { doLoginOryton } from '../ultils/doLoginOryton';
import preLoad from '../hooks/before/preLoad';

const BASE = process.env.NODE_ENV === 'development' ? 'https://api-account.oryton.vn' : 'https://api-account.oryton.no';

const RES_TYPE = {
    name: 'hook-err',
    message: '',
    data: null
};

export const singUser = (() => {
    let instance;

    function init() {
        let INFO = null; // CUR USER
        const DATA = []; // LIST USERS

        function myProfile() {
            window.location.href = process.env.NODE_ENV === 'development' ? 'https://account.oryton.vn' : 'https://account.oryton.no';
        }

        function changeLang(lang = 'en') {
            const URL = server.base() + '/apix/user/change-lang';

            return new Promise((resolve) => {
                axios
                    .post(
                        URL,
                        {
                            Language: lang
                        },
                        server.setHeaderWithBearer()
                    )
                    .then((resData) => {
                        const { data } = resData;
                        resolve({
                            ...RES_TYPE,
                            ...data
                        });
                    })
                    .catch((err) => {
                        resolve({
                            ...RES_TYPE,
                            message: err
                        });
                    });
            });
        }

        function getInfo() {
            return localDB.getOrytonUser();
        }

        function setInfo(json) {
            INFO = json;
            localDB.saveOrytonUser(INFO);
        }

        function listALl() {
            return new Promise((resolve) => {
                if (DATA.length > 0) {
                    resolve({
                        ...RES_TYPE,
                        name: 'success',
                        data: DATA
                    });
                }

                // FETDATA

                const URL = BASE + '/ajax/users/list';
                axios
                    .get(URL)
                    .then((res) => {
                        const { data } = res;

                        DATA = data.data;
                        resolve({
                            ...RES_TYPE,
                            name: 'success',
                            data: DATA
                        });
                    })
                    .catch((err) => {
                        resolve({ ...RES_TYPE, msg: err });
                    });
            });
        }

        async function checkLogIn() {
            try {
                const ORY_USER = localDB.getOrytonUser();

                if (ORY_USER) {
                    const newInfo = await _updateCheckLogin();
                    setInfo(newInfo);

                    return newInfo;
                }

                return false;
            } catch (err) {
                return err;
            }
        }

        function refreshToken() {
            return new Promise((resolve, reject) => {
                const URL = BASE + '/api/refresh-token';
                const userInfo = getInfo();

                axios
                    .post(URL, {
                        refreshToken: userInfo.RefreshToken
                    })
                    .then((res) => {
                        const { data } = res;

                        if (data.isSuccess) {
                            const TOKEN = data.data.token;
                            userInfo.token = TOKEN;
                            localDB.saveOrytonUser(userInfo);
                        }

                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(':: REFRESH TOKEN ::', err);
                    });
            });
        }

        function _updateCheckLogin() {
            return new Promise((resolve, reject) => {
                const URL = BASE + '/api/check-login';

                const userInfo = localDB.getOrytonUser();
                axios
                    .post(URL, {
                        refreshToken: userInfo.RefreshToken
                    })
                    .then((res) => {
                        const { data } = res;
                        if (data.isSuccess) {
                            localDB.saveOrytonUser(data.data);
                        }

                        resolve(data.data);
                    })
                    .catch((err) => {
                        resolve(false);
                    });
            });
        }

        function loginByToken(token = '') {
            return new Promise((resolve) => {
                const URL = server.base() + '/apix/user/orytonAuthByToken';

                axios
                    .post(URL, {
                        token
                    })
                    .then((resData) => {
                        const { data } = resData;

                        if (data.name === 'success') {
                            localDB.saveOrytonUser(data.data);
                            setInfo(data.data);
                            window.location.href = '/';
                        } else {
                            resolve({
                                ...RES_TYPE,
                                ...data
                            });
                        }
                    });
            });
        }

        function loginByPass(username = '', password = '') {
            return new Promise((resolve) => {
                preLoad('loading');
                doLoginOryton(username, password).then((res) => {
                    if (res.isSuccess) {
                        // DETECT USER ROLES : ADMIN OR ACCOUNTANT : SEE ALL
                        // EMPLOYEE : SEE PAYSLIP
                        // OWNER : SEE ALL
                        localDB.saveOrytonUser(res.data);
                        setInfo(res.data);

                        window.location.href = '/';
                    } else {
                        resolve(res.message);
                    }
                });
            });
        }

        function logout() {
            preLoad('loading');

            const url = BASE + '/api/logout';

            axios.post(url).then((res) => {
                const { data } = res;
                if (data.isSuccess) {
                    // logout from server
                    // remove local data
                    // REFESH
                    localDB.removeOrytonUser();
                    localDB.removeOrytonCompany();

                    const redirectUrl = server.ORYTON_API + '/logout.html?redirect-url=' + server.thisApp();
                    window.location.href = redirectUrl;
                }
            });
        }

        return {
            myProfile,
            changeLang,
            getInfo,
            setInfo,
            listALl,
            checkLogIn,
            loginByToken,

            logout,
            updateCheckLogin: _updateCheckLogin,
            refreshToken
        };
    }

    return {
        start() {
            if (!instance) instance = init();

            return instance;
        }
    };
})().start();
