
import SALARY_TYPE from '../config/SALARY_TYPES.json';

/**
 * 
 * @param {string} code  
 * @return {Object} {tax,aga,vacation}
 */
const allDefaultMustPayTax = (code='')=>{

    const data = [...SALARY_TYPE];

    return data.find(item=>{
        return item.code === code
    });

    

}

export default allDefaultMustPayTax 