import _ from 'lodash';
import APP_CONFIG from '../../config/app.json';
import { localDB } from '../../models/localData';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

// CONTEXT
import { LayoutContext } from '..';

import Icon from '../../components/Icon';
import MALE from '../../assets/male.png';

const _getFlatIcon = _.memoize((lang) => {
    const BOARD = {
        en: 'flag-icon-gb',
        vi: 'flag-icon-vn',
        no: 'flag-icon-no'
    };

    return BOARD[lang];
});

export const ButtonDrop = React.memo(({ onDropdown = () => {}, icon = '', title = null, name = '' }) => {
    return (
        <button
            onClick={() => {
                onDropdown('language');
            }}
            style={{ marginTop: -2 }}
            className="btn btn-normal nowrap"
        >
            {icon !== '' ? <span className={`flag-icon ${icon}`} /> : null}
            <span style={{ marginLeft: icon !== '' ? 10 : 0 }} className="hide-on-med-and-down">
                {title}
                <svg style={{ marginTop: -2 }} height="18" viewBox="0 0 18 18">
                    <path d="M7 10l5 5 5-5z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </span>

            <div className="hide-on-large-only">{name && <Icon name={name} size={18} />}</div>
        </button>
    );
});

ButtonDrop.displayName = 'ButtonDrop';

const ItemUserProfile = React.memo(({ onDropdown = () => {}, onChangeProfileItem = () => {}, userInfo = {}, localState = {} }) => {
    const { langs, profile } = APP_CONFIG;
    const { t } = useTranslation();

    const avatarUrl = userInfo.Avatar || MALE;
    return (
        <li
            style={{
                marginRight: 15,
                position: 'relative'
            }}
        >
            <button
                onClick={() => onDropdown('profile')}
                style={{
                    marginTop: -2,
                    paddingTop: 6.625,
                    display: 'flex',
                    justifyContent: 'center'
                }}
                className="btn btn-normal nowrap"
            >
                <img className="sm-avatar pt-0" src={avatarUrl} alt="" />

                <span className="hide-on-med-and-down">
                    <span className="pl-10 pr-5">{userInfo.FirstName + ' ' + userInfo.LastName}</span>
                    <svg style={{ marginTop: -2 }} className="caret" height="18" viewBox="0 0 18 18" width="18">
                        <path d="M7 10l5 5 5-5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </span>
            </button>

            <ul
                style={{
                    display: localState.curDropDown === 'profile' ? 'block' : 'none',
                    opacity: 1,
                    right: 20,
                    top: 52,
                    width: 200
                }}
                className="dropdown-content hide-hide-on-large-only"
                id="profile-dropdown"
            >
                {profile.map((item, index) => {
                    return (
                        <li key={index}>
                            <a className="grey-text text-darken-1" href="#!" onClick={() => onChangeProfileItem(item?.code)}>
                                <i className="material-icons">{item.icon}</i>
                                {t(item.name)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
});

ItemUserProfile.displayName = 'ItemUserProfile';

const ItemLang = React.memo(({ onDropdown = () => {}, onChangeLang = () => {}, localState = {} }) => {
    const { t } = useTranslation();
    const { langs } = APP_CONFIG;

    return (
        <li
            className="hide-on-med-and-down"
            style={{
                marginRight: 10,
                position: 'relative'
            }}
        >
            <ButtonDrop
                onDropdown={() => {
                    onDropdown('language');
                }}
                icon={_getFlatIcon(localDB.myOwn.getKey('Language'))}
            />
            <ul
                style={{
                    display: localState.curDropDown === 'language' ? 'block' : 'none',
                    opacity: 1,
                    left: -134,
                    top: 52,
                    width: 200
                }}
                className="dropdown-content"
                id="translation-dropdown"
            >
                {langs.map((item, index) => {
                    return (
                        <li key={index}>
                            <a className="grey-text text-darken-1" data-language={item.icon} onClick={() => onChangeLang(item?.code)}>
                                <i className={'flag-icon flag-icon-' + item.code} />
                                {t(item.name)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
});
ItemLang.displayName = 'ItemLang';

const ItemService = React.memo(({ onDropdown = () => {}, onChangeService = () => {}, localState = {} }) => {
    const { services } = APP_CONFIG;
    const { t } = useTranslation();

    return (
        <li
            style={{
                marginRight: 15,
                position: 'relative'
            }}
        >
            <ButtonDrop name="services" onDropdown={() => onDropdown('service')} title={t('Services')} />

            <ul
                style={{
                    display: localState.curDropDown === 'service' ? 'block' : 'none',
                    opacity: 1,
                    top: 52,
                    width: 200
                }}
                className="dropdown-content"
                id="profile-dropdown"
            >
                {services.map((item, index) => {
                    return (
                        <li key={index}>
                            <a
                                className="grey-text text-darken-1 relative"
                                onClick={() => onChangeService(item)}
                                style={{ lineHeight: '20px' }}
                            >
                                <div className="inline-block" style={{ width: 50 }}>
                                    <img src={item.icon} style={{ height: 27 }} />
                                </div>
                                <div className="inline-block">
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: 18
                                        }}
                                    >
                                        {t(item.name)}
                                    </span>
                                </div>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
});

ItemService.displayName = 'ItemService';

export const HeaderRightSide = ({ stateHelper = Object, companyInfo = null }) => {
    const layoutHelper = useContext(LayoutContext);
    const { ctrl } = layoutHelper;

    const [localState, setLocalState] = useState({
        curDropDown: ''
    });

    const myRef = useRef();
    useEffect(() => {
        if (companyInfo) {
            try {
                const handleClickOutside = (e) => {
                    if (!myRef.current.contains(e.target)) {
                        _onDropdown(null);
                    }
                };

                document.addEventListener('mousedown', handleClickOutside);
                return () => document.removeEventListener('mousedown', handleClickOutside);
            } catch (err) {}
        }
    }, [companyInfo]);

    const _onDropdown = (item) => {
        setLocalState((prev) => {
            return {
                ...prev,
                curDropDown: prev.curDropDown === item ? '' : item
            };
        });
    };

    const _onChangeService = useCallback((item) => {
        ctrl.onChangeService(item);
    }, []);

    const _onChangeLang = useCallback((code) => {
        ctrl.onChangeLang(code);
    }, []);

    const _onChangeProfileItem = useCallback((code) => {
        ctrl.onProfileItem(code);
    }, []);

    const { state } = stateHelper;

    return companyInfo ? (
        <ul className="navbar-list right" ref={myRef}>
            {/* SEARCH BOX */}
            <li style={{ marginRight: 15 }}>
                <button
                    style={{
                        marginTop: -2,
                        paddingTop: 6.625,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    className="btn btn-normal"
                >
                    <Icon name="search" size={18} />
                </button>
            </li>

            {/** NOTIFICATION */}
            <li style={{ marginRight: 15 }}>
                <button
                    style={{
                        marginTop: -2,
                        paddingTop: 6.625,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    className="btn btn-normal"
                >
                    <Icon name="bell" size={18} />
                </button>
            </li>

            {/* COMPANY  */}
            {companyInfo ? (
                <li style={{ marginRight: 15 }}>
                    <button
                        onClick={() => {
                            layoutHelper.toggleModal('isOpenForm');
                        }}
                        style={{
                            marginTop: -2,
                            paddingTop: 6.625,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        className="btn btn-normal"
                    >
                        <Icon name="group" size={18} />

                        <div className="hide-on-med-and-down nowrap">
                            <span className="pl-10 pr-5">{companyInfo.CompanyName}</span>

                            <svg style={{ marginTop: -2 }} height="18" viewBox="0 0 18 18">
                                <path d="M7 10l5 5 5-5z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                        </div>
                    </button>
                </li>
            ) : null}

            {/* SERVICES */}
            <ItemService onDropdown={_onDropdown} onChangeService={_onChangeService} localState={localState} />

            {/* USER PROFILE */}
            <ItemUserProfile
                onDropdown={_onDropdown}
                onChangeProfileItem={_onChangeProfileItem}
                userInfo={state.userInfo}
                localState={localState}
            />

            {/** LANGUAGES */}
            <ItemLang onDropdown={_onDropdown} onChangeLang={_onChangeLang} localState={localState} />
        </ul>
    ) : null;
};
