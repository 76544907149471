
function doPushFormErr(msg = "") {
    try{
        let el = document.querySelector("#form-err");
        el.innerHTML = '<span class="red-text">' + msg + "</span>";

        window.setTimeout(() => {
            el.innerHTML = "";
        }, 3000);
        
    }catch(err){}
    

    
}


export default doPushFormErr;
