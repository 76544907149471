import PropTypes from "prop-types";

const defaultProps = {
    name: "group",
    size: 32,
    color: "#5A6978",
    style: {},
};
const propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.object,
};
const Icon = (props) => {
    const { name, size, color, style } = props;
    const data = {
        trash: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                viewBox="0 0 24 24"
                style={style}
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                >
                    <g stroke={color} strokeWidth="1.5">
                        <g>
                            <path
                                d="M19 4L19 22 3 22 3 4M0 4L22 4M11 10L11 16M7 10L7 16M15 10L15 16M7 4L7 0 15 0 15 4"
                                transform="translate(-1353.000000, -675.000000) translate(1354.000000, 676.000000)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        ),
        arrow_up_thin: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                viewBox="0 0 16 20"
            >
                <g fill="none" fillRule="evenodd" strokeLinecap="round">
                    <g stroke="#000">
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M7 13L7 0M12 5L7 0 2 5M0 13L0 18 14 18 14 13"
                                        transform="translate(-712.000000, -413.000000) translate(538.000000, 241.000000) translate(20.000000, 151.000000) translate(155.000000, 22.000000)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        arrow_up: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                viewBox="0 0 9 5"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill={color}>
                        <path
                            d="M220.5 685L225 690 216 690z"
                            transform="translate(-216.000000, -685.000000)"
                        />
                    </g>
                </g>
            </svg>
        ),
        arrow_down: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                viewBox="0 0 9 5"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill={color}>
                        <g>
                            <path
                                d="M220.5 25L225 30 216 30z"
                                transform="translate(-216.000000, -573.000000) translate(0.000000, 548.000000) translate(220.500000, 27.500000) scale(1, -1) translate(-220.500000, -27.500000)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        ),
        settings: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -861.000000) translate(0.000000, 340.000000) translate(0.000000, 504.000000) translate(26.000000, 17.000000)"
                                    />
                                    <g
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            fill={color}
                                            stroke="#5A6978"
                                            d="M18 10.636V7.364h-2.669c-.155-.6-.39-1.164-.697-1.684l1.887-1.887-2.314-2.314-1.887 1.887c-.52-.307-1.085-.542-1.684-.697V0H7.364v2.669c-.6.155-1.164.39-1.684.697L3.793 1.479 1.48 3.793 3.366 5.68c-.307.52-.542 1.085-.697 1.684H0v3.272h2.669c.155.6.39 1.164.697 1.684l-1.887 1.887 2.314 2.314 1.887-1.887c.52.307 1.085.542 1.684.697V18h3.272v-2.669c.6-.155 1.164-.39 1.684-.697l1.887 1.887 2.314-2.314-1.887-1.887c.307-.52.542-1.085.697-1.684H18z"
                                            transform="translate(-26.000000, -861.000000) translate(0.000000, 340.000000) translate(0.000000, 504.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            stroke="#FFF"
                                            strokeWidth="2"
                                            d="M9 6.545c1.356 0 2.455 1.1 2.455 2.455 0 1.356-1.1 2.455-2.455 2.455-1.356 0-2.455-1.1-2.455-2.455 0-1.356 1.1-2.455 2.455-2.455h0z"
                                            transform="translate(-26.000000, -861.000000) translate(0.000000, 340.000000) translate(0.000000, 504.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        report: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -804.000000) translate(0.000000, 340.000000) translate(0.000000, 448.000000) translate(26.000000, 16.000000)"
                                    />
                                    <g
                                        fill={color}
                                        stroke="#5A6978"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            d="M10 18L6 18 6 10 10 10zM4 18L0 18 0 13 4 13zM10 5L12 5 12 18 16 18 16 5 18 5 14 0z"
                                            transform="translate(-26.000000, -804.000000) translate(0.000000, 340.000000) translate(0.000000, 448.000000) translate(26.000000, 16.000000) translate(1.000000, 1.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        overview: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -749.000000) translate(0.000000, 340.000000) translate(0.000000, 392.000000) translate(26.000000, 17.000000)"
                                    />
                                    <g fill={color}>
                                        <path
                                            d="M0 1.997C0 .894.896 0 1.997 0h5.006C8.106 0 9 .896 9 1.997v5.006C9 8.106 8.104 9 7.003 9H1.997C.894 9 0 8.104 0 7.003V1.997zM11 1.997C11 .894 11.896 0 12.997 0h5.006C19.106 0 20 .896 20 1.997v5.006C20 8.106 19.104 9 18.003 9h-5.006C11.894 9 11 8.104 11 7.003V1.997zM0 12.997C0 11.894.896 11 1.997 11h5.006C8.106 11 9 11.896 9 12.997v5.006C9 19.106 8.104 20 7.003 20H1.997C.894 20 0 19.104 0 18.003v-5.006zM11 12.997c0-1.103.896-1.997 1.997-1.997h5.006c1.103 0 1.997.896 1.997 1.997v5.006C20 19.106 19.104 20 18.003 20h-5.006C11.894 20 11 19.104 11 18.003v-5.006z"
                                            transform="translate(-26.000000, -749.000000) translate(0.000000, 340.000000) translate(0.000000, 392.000000) translate(26.000000, 17.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        salary: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 18"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V18H0z"
                                        transform="translate(-26.000000, -694.000000) translate(0.000000, 340.000000) translate(0.000000, 336.000000) translate(26.000000, 18.000000)"
                                    />
                                    <g
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            fill={color}
                                            stroke={
                                                color === "#5A6978"
                                                    ? "#fff"
                                                    : "#5A6978"
                                            }
                                            strokeWidth="2"
                                            d="M18 14L0 14 0 0 18 0z"
                                            transform="translate(-26.000000, -694.000000) translate(0.000000, 340.000000) translate(0.000000, 336.000000) translate(26.000000, 18.000000) translate(1.000000, 2.000000)"
                                        />
                                        <path
                                            stroke={
                                                color === "#fff"
                                                    ? "#5A6978"
                                                    : "#fff"
                                            }
                                            strokeWidth="2"
                                            d="M8 6.9c0-.497.403-.9.9-.9s.9.403.9.9-.403.9-.9.9-.9-.403-.9-.9h0z"
                                            transform="translate(-26.000000, -694.000000) translate(0.000000, 340.000000) translate(0.000000, 336.000000) translate(26.000000, 18.000000) translate(1.000000, 2.000000)"
                                        />
                                        <path
                                            stroke={
                                                color === "#fff"
                                                    ? "#5A6978"
                                                    : "#fff"
                                            }
                                            strokeWidth="1.5"
                                            d="M13.375 3h-8.75C4.625 4.326 3.45 5.4 2 5.4v3.2c1.45 0 2.625 1.074 2.625 2.4h8.75c0-1.326 1.175-2.4 2.625-2.4V5.4c-1.45 0-2.625-1.074-2.625-2.4h0z"
                                            transform="translate(-26.000000, -694.000000) translate(0.000000, 340.000000) translate(0.000000, 336.000000) translate(26.000000, 18.000000) translate(1.000000, 2.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        other: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -637.000000) translate(0.000000, 340.000000) translate(0.000000, 280.000000) translate(26.000000, 17.000000)"
                                    />
                                    <g
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            fill={color}
                                            stroke="#5A6978"
                                            strokeWidth="2"
                                            d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9h0z"
                                            transform="translate(-26.000000, -637.000000) translate(0.000000, 340.000000) translate(0.000000, 280.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <g fill="#FFF">
                                            <path
                                                d="M1.8 0c.995 0 1.8.805 1.8 1.8s-.805 1.8-1.8 1.8S0 2.795 0 1.8.805 0 1.8 0zM7.2 0C8.194 0 9 .805 9 1.8s-.806 1.8-1.8 1.8c-.995 0-1.8-.805-1.8-1.8S6.205 0 7.2 0zM12.6 0c.995 0 1.8.805 1.8 1.8s-.806 1.8-1.8 1.8c-.995 0-1.8-.805-1.8-1.8S11.605 0 12.6 0z"
                                                transform="translate(-26.000000, -637.000000) translate(0.000000, 340.000000) translate(0.000000, 280.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000) translate(1.800000, 7.200000)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        bank: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -581.000000) translate(0.000000, 340.000000) translate(0.000000, 224.000000) translate(26.000000, 17.000000)"
                                    />
                                    <g>
                                        <path
                                            fill={color}
                                            stroke="#5A6978"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 0L0 4.909 0 7.364 18 7.364 18 4.909z"
                                            transform="translate(-26.000000, -581.000000) translate(0.000000, 340.000000) translate(0.000000, 224.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            stroke={color}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6.464 17.182L6.464 7.364M11.536 7.364L11.536 17.182M1.636 17.182L1.636 7.364M16.364 7.364L16.364 17.182"
                                            transform="translate(-26.000000, -581.000000) translate(0.000000, 340.000000) translate(0.000000, 224.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M7.364 4.09c0-.902.733-1.635 1.636-1.635.903 0 1.636.733 1.636 1.636 0 .903-.733 1.636-1.636 1.636-.903 0-1.636-.733-1.636-1.636z"
                                            transform="translate(-26.000000, -581.000000) translate(0.000000, 340.000000) translate(0.000000, 224.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            stroke={color}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M0 17.591L18 17.591"
                                            transform="translate(-26.000000, -581.000000) translate(0.000000, 340.000000) translate(0.000000, 224.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        bilagsky: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 0H20V20H0z"
                                        transform="translate(-26.000000, -525.000000) translate(0.000000, 340.000000) translate(0.000000, 168.000000) translate(26.000000, 17.000000)"
                                    />
                                    <g
                                        stroke={color}
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    >
                                        <path
                                            d="M8 6L8 8.817 5 11M8 9L11 11"
                                            transform="translate(-26.000000, -525.000000) translate(0.000000, 340.000000) translate(0.000000, 168.000000) translate(26.000000, 17.000000) translate(2.000000, 2.000000)"
                                        />
                                        <path
                                            fill={color}
                                            strokeLinecap="round"
                                            d="M8 0c1.657 0 3 1.343 3 3S9.657 6 8 6 5 4.657 5 3s1.343-3 3-3h0zM3 10c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3h0zM13 10c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3h0z"
                                            transform="translate(-26.000000, -525.000000) translate(0.000000, 340.000000) translate(0.000000, 168.000000) translate(26.000000, 17.000000) translate(2.000000, 2.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        voucher: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 16 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-28.000000, -469.000000) translate(0.000000, 340.000000) translate(0.000000, 112.000000) translate(29.000000, 18.000000)">
                                    <path
                                        fill={color}
                                        stroke={color}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M0 0L0 0 14 0 14 18 0 18z"
                                    />
                                    <path
                                        stroke={
                                            color === "#162C41"
                                                ? "#fff"
                                                : "#162C41"
                                        }
                                        strokeLinecap="round"
                                        strokeWidth="1.5"
                                        d="M7.855 14.809L3.355 14.809"
                                    />
                                    <circle
                                        cx="6"
                                        cy="5"
                                        r="3"
                                        fill={
                                            color === "#162C41"
                                                ? "#fff"
                                                : "#162C41"
                                        }
                                    />
                                    <path
                                        stroke={
                                            color === "#162C41"
                                                ? "#fff"
                                                : "#162C41"
                                        }
                                        strokeLinecap="round"
                                        strokeWidth="1.5"
                                        d="M11.455 10.536L3.355 10.536"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        contacts: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 18 18"
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g fill={color} stroke={color}>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M12.19 9.905L9.905 12.19 3.81 6.095 6.095 3.81 2.285 0 0 2.286C0 9.86 6.14 16 13.714 16L16 13.714l-3.81-3.81h0z"
                                            transform="translate(-27.000000, -414.000000) translate(0.000000, 340.000000) translate(0.000000, 56.000000) translate(26.000000, 17.000000) translate(2.000000, 2.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        buy: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            fill={color}
                                            stroke={color}
                                            d="M7.921-.5H-.5v8.421l10.786 10.786 8.421-8.421L7.921-.5z"
                                            transform="translate(-26.000000, -357.000000) translate(0.000000, 340.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            fill={
                                                color === "#fff"
                                                    ? "#162C41"
                                                    : "#fff"
                                            }
                                            stroke={
                                                color === "#fff"
                                                    ? "#162C41"
                                                    : "#fff"
                                            }
                                            d="M5.143 3.429c.947 0 1.714.767 1.714 1.714S6.09 6.857 5.143 6.857 3.429 6.09 3.429 5.143s.767-1.714 1.714-1.714h0z"
                                            transform="translate(-26.000000, -357.000000) translate(0.000000, 340.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                        <path
                                            stroke={
                                                color === "#fff"
                                                    ? "#162C41"
                                                    : "#fff"
                                            }
                                            strokeWidth="1.5"
                                            d="M10.286 7.714L12.857 10.286M7.714 10.286L10.286 12.857"
                                            transform="translate(-26.000000, -357.000000) translate(0.000000, 340.000000) translate(26.000000, 17.000000) translate(1.000000, 1.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        sales: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 20 20"
            >
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <path
                                d="M0 0H20V20H0z"
                                transform="translate(-26.000000, -117.000000) translate(26.000000, 117.000000)"
                            />
                            <path
                                fill={color}
                                stroke={color}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 .683L1 8.397V19h18V8.397L10 .683z"
                                transform="translate(-26.000000, -117.000000) translate(26.000000, 117.000000)"
                            />
                            <path
                                stroke={
                                    color === "#162C41" ? "#fff" : "#162C41"
                                }
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6.19 15.238L13.81 15.238"
                                transform="translate(-26.000000, -117.000000) translate(26.000000, 117.000000)"
                            />
                            <path
                                fill={color === "#162C41" ? "#fff" : "#162C41"}
                                d="M13 9c0 1.658-1.342 3-3 3s-3-1.342-3-3c0-1.657 1.342-3 3-3s3 1.343 3 3z"
                                transform="translate(-26.000000, -117.000000) translate(26.000000, 117.000000)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        ),
        search: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 23 23"
            >
                <g fill="none" fillRule="evenodd">
                    <g stroke={color} strokeWidth="2">
                        <g>
                            <g>
                                <path
                                    d="M21 21L15.4 15.4M9 0c4.971 0 9 4.029 9 9s-4.029 9-9 9-9-4.029-9-9 4.029-9 9-9h0z"
                                    transform="translate(-873.000000, -39.000000) translate(874.000000, 40.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        bell: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 26 27"
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g strokeWidth="2">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            stroke="#5A6978"
                                            d="M7 21v.097C7 22.742 8.517 24 10.5 24s3.5-1.258 3.5-2.903V21"
                                            transform="translate(-941.000000, -36.000000) translate(924.000000, 20.000000) translate(19.000000, 18.000000)"
                                        />
                                        <path
                                            fill="#5A6978"
                                            stroke="#E9EEF2"
                                            d="M11-1c-2.424 0-4.598.97-6.168 2.547C3.265 3.12 2.3 5.304 2.3 7.737v3.316c0 1.765-.953 2.743-1.73 3.602-.896.993-1.57 1.892-1.57 3.03 0 .736.353 1.473 1.182 2.116.601.465 1.481.893 2.62 1.236C4.776 21.632 7.584 22 11 22s6.223-.368 8.199-.963c1.138-.343 2.018-.77 2.619-1.236.83-.643 1.182-1.38 1.182-2.117 0-1.137-.674-2.036-1.57-3.029-.777-.859-1.73-1.837-1.73-3.602V7.737c0-2.433-.965-4.616-2.532-6.19C15.598-.03 13.424-1 11-1z"
                                            transform="translate(-941.000000, -36.000000) translate(924.000000, 20.000000) translate(19.000000, 18.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        group: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 24 23"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="#5A6978">
                        <g>
                            <g>
                                <path
                                    d="M3 6c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                                <path
                                    stroke="#E9EEF2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M5.01 8.5H.99c-.408 0-.783.17-1.056.447-.267.273-.434.648-.434 1.062v1.982c0 .65.287 1.591.641 2.122L1.5 16.151v2.84c0 .421.169.797.44 1.068.273.273.651.441 1.07.441h1.98c.422 0 .797-.167 1.067-.436.275-.271.443-.648.443-1.064V9.99c0-.407-.167-.78-.438-1.052-.27-.27-.642-.438-1.053-.438z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                                <path
                                    d="M19 6c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                                <path
                                    stroke="#E9EEF2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.99 8.5c-.41 0-.782.168-1.052.438-.271.271-.438.645-.438 1.053V19c0 .415.168.792.443 1.063.27.269.645.436 1.066.436h1.982c.418 0 .796-.168 1.069-.441.271-.271.44-.647.44-1.068v-2.84l1.359-2.038c.354-.53.641-1.471.641-2.122v-1.982c0-.319-.084-.617-.442-.88-.195-.142-.506-.281-1.019-.385-.744-.15-1.986-.244-4.048-.244z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                                <path
                                    d="M11 6C9.343 6 8 4.657 8 3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                                <path
                                    stroke="#E9EEF2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.005 8.5h-8.01c-.406 0-.782.167-1.055.438-.271.27-.44.643-.44 1.053v4.018c0 .652.383 1.581.857 2.056L8.5 18.207v2.784c0 .421.169.797.44 1.068.273.273.651.441 1.07.441h1.98c.422 0 .798-.169 1.069-.44.273-.273.441-.651.441-1.07v-2.783l2.143-2.142c.47-.471.857-1.395.857-2.056V9.991c0-.408-.168-.783-.44-1.054-.271-.27-.644-.437-1.055-.437z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
        services: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={size}
                style={{ marginTop: 3 }}
                viewBox="0 0 24 23"
            >
                <g fill="none" fillRule="evenodd">
                    <g fill="#5A6978">
                        <g>
                            <g>
                                <path
                                    fill="#5A6978"
                                    stroke="#E9EEF2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 3c-4.006 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408-.212-3.951-3.473-7.092-7.479-7.092z"
                                    transform="translate(-1008.000000, -39.000000) translate(1009.000000, 39.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ),
    };
    return data[name];
};

Icon.defaultProps = defaultProps;
Icon.propTypes = propTypes;

export default Icon;
