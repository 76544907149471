import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { singUser } from '../models/singUser';
import { MyStateHelper } from '../modules/MyStateHelper';

// LIBS
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
//import { useParams } from 'react-router';

// COMPONENTS
import Table from '../components/Oryton/Table';
import Modal from '../components/Modal';
import { Col, InputSearch, Row } from './../components/Oryton/index';

// HOOKS
import { useWindowSize } from '../how/useWindowSize';
import { LayoutContext } from '.';

const getColums = (t, windowSize, type = 'desktop') => {
    const data = {
        desktop: [
            {
                headerName: t('Company name'),
                width: windowSize.width >= 768 ? 300 : 150
            },
            {
                headerName: t('Orgnr'),
                width: 50
            },
            {
                headerName: '',
                width: windowSize.width >= 768 ? 10 : 20
            }
        ],
        mobile: [
            {
                headerName: t('Company name'),
                width: windowSize.width >= 768 ? 200 : 150
            },
            {
                headerName: '',
                width: windowSize.width >= 768 ? 10 : 20
            }
        ]
    };

    return data[type];
};

const searchCompany = (listCompany, companyName) => {
    return _.cloneDeep(listCompany).filter((item) => {
        return (
            item?.value?.toString().indexOf(companyName) > -1 ||
            item?.label?.toString().toUpperCase().indexOf(companyName.toLocaleUpperCase()) > -1
        );
    });
};

// IMPLEMENT CONCRETE
const defaultProps = {
    isOpen: false,
    onClose: () => {},
    companyInfo: null
};

const stateHelper = new MyStateHelper();
export const ModalSelectCompany = (props) => {
    const layoutHelper = useContext(LayoutContext);
    const { t } = useTranslation();

    //let { page } = useParams();
    //page = page || 'dashboard';

    const { isOpen } = props;
    const { ctrl } = layoutHelper;

    const windowSize = useWindowSize();

    const [state, setState] = useState({
        keySearch: '',
        isOpenForm: false,
        isOpenModalActive: false,
        activeCompanyInfo: null,
        selectOptions: null, // LIST ALL COMPANY
        paddingBottom: 0,
        curCompany: null,
        userInfo: null,
        listPermission: [], // USING FOR EMPLOYEE USER CASE,
        isOpenModalSubscription: false,
        paymentLink: '',
        statusFilter: 0
    });

    stateHelper.set(state, setState);
    const IS_EMPLOYEE = useMemo(() => {
        return singUser.getInfo().RoleId <= 1;
    }, []);

    let isYourCompany = useMemo(() => {
        return layoutHelper?.state?.listCompany.some((i) => i.isYourCompany);
    }, [layoutHelper.state]);

    // WHEN

    useEffect(() => {
        if (isOpen && singUser.getInfo()) {
            stateHelper.whereStateChange({
                selectOptions: layoutHelper.state.listCompany,
                curCompany: props.companyInfo,
                userInfo: singUser.getInfo()
            });
        }
    }, [isOpen, props.companyInfo, layoutHelper?.state?.listCompany]);

    const TABLE_COLUMN = useMemo(() => {
        return getColums(t, windowSize, 'desktop');
    }, [t, windowSize]);

    const TABLE_COLUMN_MOBILE = useMemo(() => {
        return getColums(t, windowSize, 'mobile');
    }, [t, windowSize]);

    const _onFindCompany = useCallback(
        (keySearch = '') => {
            setState((prev) => {
                return {
                    ...prev,
                    selectOptions: searchCompany(layoutHelper?.state?.listCompany, keySearch),
                    keySearch
                };
            });
        },
        [layoutHelper?.state?.listCompany]
    );

    if (IS_EMPLOYEE) return null;

    return (
        <Modal width={900} isOpen={isOpen} onClose={props.onClose} title={t('Select Company')} isFooter={false}>
            <div id="modal-select-company">
                <Row>
                    <Col col={4} sm={12}>
                        <InputSearch
                            value={state.keySearch}
                            onSearch={(text) => _onFindCompany(text)}
                            onChange={(text) => _onFindCompany(text)}
                            className="w-100 m-0"
                            placeHolder={t('Search')}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col col={4} sm={12}></Col>
                    <Col col={4}>
                        {isYourCompany && (
                            <button onClick={() => layoutHelper?.ctrl?.doCustomRole(1)} className="btn  btn-normal">
                                <i className="fa fa-user mr-5" /> {t('Login as employee')}
                            </button>
                        )}
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '1rem'
                    }}
                >
                    <Col
                        col={12}
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                            // minHeight: localState.dememsion.height - 190,
                            height: 'calc(100dvh - 230px)',
                            maxHeight: windowSize.width >= 600 ? 500 : undefined,
                            overflow: 'auto',
                            position: 'relative',
                            marginLeft: 0,
                            marginRight: 0
                        }}
                    >
                        <Table
                            className="auto-scroll"
                            height={'calc(100dvh - 225px)'}
                            colums={windowSize.width >= 600 ? TABLE_COLUMN : TABLE_COLUMN_MOBILE}
                            style={{
                                overflow: 'auto',
                                maxHeight: windowSize.width >= 600 ? 500 : undefined
                            }}
                        >
                            {state.selectOptions &&
                                state.selectOptions.map((item, index) => {
                                    const BUTTONS = {
                                        'NO-SUB': (
                                            <button
                                                // onClick={() => {
                                                //     _toggleActiveService(item);
                                                // }}
                                                className="btn-sm"
                                                style={{
                                                    width: 120,
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <i className="fa fa-star mr-5"></i> {t('Buy package')}
                                            </button>
                                        ),
                                        EXPIRED: <label>{t('Renew')}</label>,
                                        LIVE: (
                                            <span
                                                className="text-green font-11"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    maxWidth: '100%',
                                                    padding: '.5rem 1rem'
                                                }}
                                            >
                                                <i className="text-green fa fa-check mr-5"></i>
                                                {t(item && item.StripeSub && item.StripeSub.StripePackageCode)}
                                            </span>
                                        )
                                    };
                                    const displayCompanyId = process.env.REACT_APP_RUN_AT === 'LOCAL' ? item.id + ' - ' : '';

                                    return (
                                        item.label !== '' && (
                                            <tr key={index}>
                                                <td
                                                    onClick={() => ctrl.accessCompany(item)}
                                                    className="cursor"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        color: item.IsSpecial === 1 ? '#18A689' : '#000000'
                                                    }}
                                                >
                                                    <label
                                                        className='Aa'
                                                        style={{
                                                            display: 'block',
                                                            fontWeight: '700',
                                                            textAlign: 'center',
                                                            lineHeight: '30px',
                                                            borderRadius: 6,
                                                            width: 30,
                                                            maxWidth: 30,
                                                            flexBasis: '100%',
                                                            height: 30,
                                                            marginRight: 15,
                                                            background: '#F5F5F5'
                                                        }}
                                                    >
                                                        {item.label.substring(0, 1)}
                                                    </label>
                                                    <div>
                                                        <p className="m-0">{displayCompanyId + item.label}</p>
                                                        {windowSize.width <= 992 && (
                                                            <small
                                                                className="m-0 mt-2 text-muted"
                                                                style={{
                                                                    fontWeight: 'lighter',
                                                                    fontStyle: 'italic'
                                                                }}
                                                            >
                                                                {item.value}
                                                            </small>
                                                        )}
                                                    </div>
                                                </td>
                                                {windowSize.width > 992 && (
                                                    <td
                                                        onClick={() => ctrl.accessCompany(item)}
                                                        
                                                        style={{
                                                            color: '#000'
                                                        }}
                                                    >
                                                        {item.value}
                                                    </td>
                                                )}
                                                <td>
                                                    <div className="w-100 display-flex justify-content-center align-items-center">
                                                        {item.IsSpecial === 1 ? '' : BUTTONS[item.StripeSub?.Status]}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    );
                                })}
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col col={12} lg={12} sm={12} md={12}>
                        <div
                            style={{
                                marginTop: 30,
                                marginBottom: -70,
                                lineHeight: '34px'
                            }}
                        >
                            <div className="float-right">
                                <button
                                    onClick={() => {
                                        ctrl.onProfileItem('logout');
                                    }}
                                    type="button"
                                    className="btn btn-normal mr-15 pr-20 pl-20"
                                >
                                    {' '}
                                    <i className="fa fa fa-reply" /> {t('Logout')}
                                </button>
                                <button
                                    onClick={() => {
                                        ctrl.onProfileItem('add_company');
                                    }}
                                    type="button"
                                    className="btn-ubuntu-ok  btn btn-secondary radius-left"
                                >
                                    {' '}
                                    <i className="fa fa-briefcase" /> {t('Add Company')}
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

ModalSelectCompany.defaultProps = defaultProps;
