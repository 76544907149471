import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const propTypes = {
    style: PropTypes.object,
    theadBG: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colums: PropTypes.array,
    rowData: PropTypes.array,
    footer: PropTypes.element,
};
const defaultProps = {
    style: {},
    theadBG: "#E5E5E5",
    colums: [
        {
            headerName: "Colum",
            field: "colum",
            width: "300px",
            textAlign: "left",
        },
    ],
    rowData: [],
    height: "auto",
    footer: <tfoot></tfoot>,
};
const Table = (props) => {
    const { height, colums, rowData, style, footer, theadBG, className } =
        props;

    const [state, setState] = useState({
        colums: [],
        rowData: [],
    });

    useEffect(() => {
        setState((prev) => {
            return {
                ...prev,
                colums: colums,
                rowData: rowData,
            };
        });
    }, [colums]);

    return (
        <table className={"oryton-table " + className} style={{ ...style }}>
            {/* HEADER */}
            <thead style={{ borderTop: 0, background: theadBG }}>
                <tr>
                    {state.colums.map((item, index) => {
                        return (
                            <th
                                key={index}
                                style={{
                                    width: item.width,
                                    maxWidth: item.maxWidth,
                                    textAlign: item.textAlign,
                                }}
                            >
                                {item.render ? (
                                    item.render()
                                ) : (
                                    <input
                                        title={item.headerName}
                                        type="text"
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 20,
                                            textTransform: "uppercase",
                                            background: "transparent",
                                            border: 0,
                                            fontSize: 12,
                                            fontWeight: "500",
                                            width: "100%",
                                            textAlign: item.textAlign,
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                        }}
                                        value={item.headerName}
                                        disabled={true}
                                    />
                                )}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            {/* BODY */}
            <tbody style={{ maxHeight: height }}>{props.children}</tbody>
            {footer}
        </table>
    );
};

Table.defaultProps = defaultProps;
Table.propTypes = propTypes;

export default Table;
