import Box404 from '../../components/Oryton/404Box';

export const Page404 = () => {
    return (
        <div style={{ width: '50wv', margin: 'auto' }}>
            <Box404
                message={
                    <>
                        <h3>Page not found</h3>
                        <p className="font-20">Sorry but the page you are looking for are not existed </p>
                        <p className="font-20">
                            <a href="/">Go back </a>
                        </p>
                    </>
                }
                type={400}
            />
        </div>
    );
};
