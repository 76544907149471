import { localDB } from '../models/localData';

export const server = (() => {
    let instance;
    let DEV;

    function init(isDev) {
        DEV = isDev;

        const SELECT_SERVER =
            process.env.REACT_APP_ENV === 'development' ? 'https://dev-accounting.oryton.no' : 'https://accounting.oryton.no';

        return {
            host: DEV ? 'http://localhost:8001' : SELECT_SERVER, //"http://localhost",//"http://dev-salary.oryton.no",,
            port: DEV ? 8001 : 443,

            url: '',
            paginate: {
                p: 0,
                offset: 0,
                max: 20,
                is_deleted: 0,
                key: '',
                sort_by: 'DateCreated',
                sort_type: 'DESC',
                creator_id: 2
            },

            ORYTON_API: process.env.REACT_APP_ENV === 'development' ? 'https://account.oryton.vn' : 'https://account.oryton.no',
            BASE_SALARY: process.env.REACT_APP_ENV === 'development' ? 'https://dev-salary.oryton.no' : 'https://salary.oryton.no',
            BASE_BILAGSKY: process.env.REACT_APP_ENV === 'development' ? 'https://dev.bilagsky.no' : 'https://bilagsky.no',
            BASE_INVOICE: process.env.REACT_APP_ENV === 'development' ? 'https://dev-invoice.oryton.no' : 'https://invoice.oryton.no',

            base() {
                return this.host;
            },

            baseAltinn() {
                return DEV ? 'http://localhost:8000' : 'https://dev-salary.oryton.no';
            },

            thisApp() {
                const port = DEV ? ':3001' : '';
                return this.host + port;
            },

            setHeaderWithBearer() {
                try {
                    const TOKEN = 'Bearer ' + localDB.getOrytonUser()['AccessToken'];

                    return {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: TOKEN,
                            'cache-control': 'no-cache'
                        }
                    };
                } catch (err) {}
            },
            /**
             *
             * @returns {object} httpHeader for making request
             */
            setHeader() {
                try {
                    const TOKEN = localDB.getOrytonUser()['AccessToken'];

                    return {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: TOKEN,
                            'cache-control': 'no-cache'
                        }
                    };
                } catch (err) {}
            },

            token() {
                return localStorage.getItem('feathers-jwt');
            },

            PDF_SERVER: 'https://dev-salary.oryton.no'
        };
    }

    return {
        start(isDev = false) {
            if (!instance) {
                instance = new init(isDev);
            }

            return instance;
        }
    };
})().start(process.env.REACT_APP_RUN_AT === 'LOCAL');
