
import csc from "country-state-city";
const LISTALL_COUNTRY = csc.getAllCountries() ;


/**
 * convert country code => phonecode
 * @param {string} countryCode 
 * @returns {string} phoneCode
 */
const getPhoneCode = (countryCode='')=>{


    const info = LISTALL_COUNTRY.find(item=>item.isoCode === countryCode) ;

    if(!info)
        return 

    return '+'+info.phonecode.replace('+','');
    
}

export default getPhoneCode ;