

/**
 * 
 * @param {Array} list 
 * @param {number} id 
 * @returns bool
 */

const myType = false; 
export default function isExistedInList(list=[],id=0){

  let ret = myType

  if(list.length>0){

    list.map((item)=>{
      if(parseInt(item.id) === id){
        ret = true;
      }
    })

  }


  return ret ;
}

