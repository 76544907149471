
import PropTypes from 'prop-types';

/**
 * THIS COMPONENT ONLY USE IN CASE LEFT ASIDE PAGE
 * @param {} param0 
 * @returns 
 */
const OryIcon = ({icon='', size=22, color='', style={} })=>{

    return(
        <i style={{ fontSize:size, ...style}} className={`material-icons ${color}`}>{icon}</i>
    )
}

OryIcon.defaultProps = {
    icon:'',
    size:22
}
OryIcon.propTypes = {
    icon:PropTypes.string,
    size:PropTypes.number
}
export default OryIcon; 