/* detech form */

/**
 *
 * @param {Array} fields
 * @param {Object} data
 * @returns Object and side effect : #form-err - input
 */

 const myType = {
    is_success: true, 
    msg: "",
};

export default function detectForm(fields = [], data = {}, elForm=null) {
    const ret = { ...myType };

    if (fields.length > 0) {
        fields = fields.reverse();

        

        Object.keys(data).map((item) => {
            fields.map((item2) => {

                
                if (
                    data[item2].length === 0 ||
                    data[item2] === "" ||
                    data[item2] === "0" ||

                    data[item2] === 0 ||
                    JSON.stringify(data[item2]) === "{}"
                ) { 
                    try {
                        ret.is_success = false;
                        ret.msg = "Please check " + item2;
                        ret.field = item2 ;
                        
                        
                        if(elForm){
                            
                            document.querySelector(elForm+' #'+item2).focus();
                            
                        }else{
                            document.getElementById(item2).focus();
                        }
                    
                    } catch (err) {}
                }
            });
        });
    }

    try {
        let el =  elForm ? document.querySelector(elForm+' '+'#form-err') : document.querySelector("#form-err");
        el.innerHTML = '<span class="red-text"> ' + ret.msg + "</span>";

        if (!ret.is_success) {
            window.setTimeout(() => {
                ret.is_success = true;
                el.innerHTML = '' ; 
                    
            }, 3000);
        }
    } catch (err) {}

    return ret;
    
}
