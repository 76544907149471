
import SETTINGS  from "../config/settings.json";
const {VAT_CODES} = SETTINGS ;

/**
 * LẤY DANH SÁCH CÁC MÃ VAT CODE ĐƯA VÀO SELECT LIST
 * @param {number} group 
 * @returns {JSON} {}
 */
function getVatCodeInfo(code=0){
    let arr = null
    
    try{

        return VAT_CODES.find(item=>item.VatCode.toString() === code.toString()) 
        

    }catch{}

    return arr

}

export default getVatCodeInfo ; 
