
import numeral from 'numeral';

function formatCurrency(number){

    // CHANGE .-> " "
    // CHANGE .-> " "
    let num =  numeral(number).format('0,0.00').toString().replace(/,/g,' ');
    return num.replace(".",",");
    
}

export default formatCurrency ; 
