

class EventAction  {

    _instance = null
    _STATE =  {};
    _OBSERVER =  {};

    constructor(){

        if(!EventAction._instance){
            EventAction._instance = this;
        }

        return EventAction._instance ;
    }

    onStateChange(prop = '', callback = (data)=>{}) {
        if (!this._OBSERVER[prop]) {
          this._OBSERVER[prop] = [];
        }
    
        this._OBSERVER[prop].push(callback);
    }

    setData(key = "", value = {}) {
        this._STATE[key] = value;
        this._notify(key);
    }
    
    _notify(key = '') {
        try {
          this._OBSERVER[key].forEach((obj) => {
            obj(this._STATE[key]);
          });
        } catch {}
    }
    
    getData(key = '') {
        return this._STATE[key];
    }
    
    onUnMount(key = '') {
         delete this._OBSERVER[key];
    }


} 

export default EventAction ; 