import 'react-toastify/dist/ReactToastify.css';

import { routes } from '../routes';
// HELPERS
import { RouterHelper } from '../modules/RouterHelper';

// MVC
import { MyStateHelper } from '../modules/MyStateHelper';
import { LayoutHandler } from '../controller/Layout.Handler';

// LIBS
import { useEffect, useRef, useState, createContext, useCallback } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

// COMPONENTS
import { Header } from './Header';
import { LeftAside } from './LeftAside';
import { ModalSelectCompany } from './ModalSelectCompany';
import { ToastContainer } from 'react-toastify';
import { InvalidPage } from './Components/InvalidAccess';

// HOOKS
import { useWindowSize } from './../how/useWindowSize';

// IMPLEMENT CONCRETE
const stateHelper = new MyStateHelper();
const myHandler = new LayoutHandler();

export const LayoutContext = createContext();

export const DefaultLayout = (props) => {
    // ADD PLUGIN
    window.APP.routerHelper = RouterHelper.start(props);
    const { company } = useParams();
    const [state, setState] = useState(myHandler.stateBuilder());

    const windowSize = useWindowSize();

    stateHelper.set(state, setState);

    // CONNECT
    // V <--> CTRL
    stateHelper.connectCtrl(myHandler);

    useEffect(() => {
        let mounted = true;
        if (mounted) myHandler.boot(company);

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mainEl = document.getElementById('main');

        if ((windowSize.width || windowSize.height <= 600) && mainEl) {
            if (windowSize.height > 600) mainEl.style.overflow = 'hidden';
            else mainEl.style.overflow = 'auto';
            mainEl.style.maxHeight = 'calc(100dvh - 56px)';
        }
    }, [windowSize]);

    const _toggleModalCompany = () => {
        company && stateHelper.toggleModal('isOpenForm');
    };

    return state.IsAccess ? (
        <LayoutContext.Provider value={stateHelper}>
            <div className="site-overlay"></div>

            <ModalSelectCompany companyInfo={state.companyInfo} isOpen={state.isOpenForm} onClose={_toggleModalCompany} />

            <div className="ory_layout">
                <div className="ory_header">
                    <Header onOpenModal={_toggleModalCompany} companyInfo={state.companyInfo} />
                </div>
                <div className="ory_leftSide">
                    <LeftAside />
                </div>
            </div>

            <div id="main">
                <div className="container" style={{ paddingTop: 0, paddingLeft: 10 }}>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                                <Route
                                    key={idx}
                                    path={'/' + company + route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={(props) => <route.component {...props} />}
                                />
                            ) : null;
                        })}

                        <Redirect from="/" to="/company" />
                    </Switch>
                    <ToastContainer autoClose={2000} />
                </div>
            </div>
            <footer
                style={{
                    paddingTop: 100,
                    paddingBottom: 30,
                    textAlign: 'center',
                    display: 'none'
                }}
            >
                © 2021 <b style={{ color: '#162C41' }}>Oryton</b> Inc. Made with ❤︎ in Viet Nam.
            </footer>
        </LayoutContext.Provider>
    ) : (
        <InvalidPage />
    );
};
