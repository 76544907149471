import { useEffect } from "react";

const UseClickOutSide = (myRef = null, setState = () => {}) => {
    try {
        useEffect(() => {
            const handleClickOutside = (e) => {
                try {
                    if (!myRef.current.contains(e.target)) {
                        setState((prev) => {
                            return {
                                ...prev,
                                isOpen: false,
                            };
                        });
                    }
                } catch (err) {}
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () =>
                document.removeEventListener("mousedown", handleClickOutside);
        }, []);
    } catch (err) {
        alert("shit happening at useClickOutSide " + err);
    }
};

export default UseClickOutSide;
