// WHO
import UUID from '../../ultils/UUID';
import { Helper } from '../../ultils/help';
import { useTranslation } from 'react-i18next';
import {orytonTime} from '../../modules/orytonTime';

// HOW

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import OryIcon from '../OrytonIcon';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export const Loading = loadable(() => import('./Loading'));
export const FileUpload = loadable(() => import('./FileUpload'));

// FORM GROUP
export const FormGroup = ({ style = {}, alignCenter = false, className = '', children = React.Component }) => {
    return (
        <div className={`row form-group ${alignCenter ? 'form-align-center' : ''} ${' ' + className} `} style={{ ...style }}>
            {children}
        </div>
    );
};
FormGroup.defaultProps = {
    style: {},
    alignCenter: false
};

FormGroup.propTypes = {
    style: PropTypes.object,
    alignCenter: PropTypes.bool
};
// END FORMGROUP

// FIELDSET
export const FieldSet = (props) => {
    const { title, right, style } = props;
    return (
        <div className="fieldset" style={{ ...style }}>
            <div>
                <h3 className="float-left">{title}</h3>
                <div className="float-right">{right}</div>
                <div className="clear"></div>
            </div>
            <div>{props.children}</div>
        </div>
    );
};

FieldSet.defaultProps = {
    title: <label></label>,
    right: <label></label>,
    style: {}
};
FieldSet.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    style: PropTypes.object
};
/// END FIELDSET

////////// ROW /////
export const Row = (props) => {
    const { className } = props || '';
    return (
        <div {...props} className={`row ${className}`} style={{ ...props.style }}>
            <React.Fragment>{props.children}</React.Fragment>
        </div>
    );
};
Row.defaultProps = {
    style: {},
    className: ''
};

Row.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
};

//// END ROW /////////

/////// COL //////
//className={"col s12 m6 l6 xl" + col + " " + className}
export const Col = ({ col = 0, sm = 0, md = 0, lg = 0, style = {}, className = '', children = React.Component }) => {
    const xl = col === 0 ? '' : 'xl' + col;
    const s = sm === 0 ? '' : 's' + sm;
    const m = md === 0 ? '' : 'm' + md;
    const l = lg === 0 ? '' : 'l' + lg;
    return (
        <div className={`col ${xl} ${s} ${m} ${l} ${className}`} style={{ position: 'relative', lineHeight: '32px', ...style }}>
            <React.Fragment>{children}</React.Fragment>
        </div>
    );
};

Col.defaultProps = {
    col: 12,
    style: {},
    className: ''
};

Col.propTypes = {
    col: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string
};

////////////// END COL /////

/* Input */

export const Input = (props) => {
    const { className, autoSuggestion, type = 'text', error, limit, value, onChange = () => {}, disabled, style, isRadius } = props;

    const [internalError, setInternalError] = useState(null);

    const suggestionRef = useRef();

    useEffect(() => {
        if (autoSuggestion.length > 0) {
            const handleClickOutside = (e) => {
                if (!suggestionRef.current.contains(e.target)) {
                    props.onClickOutSide();
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [autoSuggestion]);
    useEffect(() => {
        setInternalError(error);
    }, [error]);
    const _onChange = (e) => {
        const value = e.target.value;

        if (type === 'number') {
            if (!isNaN(value) && value.length <= limit) {
                onChange(value);
            }
        } else {
            if (limit > 0) {
                if (value.length <= limit) {
                    onChange(value);
                }
            } else {
                onChange(value);
            }

            //  EMAIL VALIDATE
            if (type === 'email') {
                setInternalError(!Helper.validateEmail(value) ? 'Invalid email' : null);

                if (value === '') {
                    setInternalError(null);
                }
            }
        }
    };

    // auto clean up

    const cssRadius = isRadius ? 'radius-left radius-right' : '';
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <input
                {...props}
                type={type}
                className={`form-control  ` + className}
                onChange={(e) => {
                    _onChange(e);
                }}
                defaultValue={value}
                style={{
                    width: '100%'
                }}
            />

            {/* DISPLAY ERROR */}
            {internalError ? (
                <label
                    style={{
                        position: 'absolute',
                        zIndex: 1003,
                        height: 16,
                        lineHeight: '14px',
                        marginTop: -9,
                        left: 0,
                        paddingLeft: 4
                    }}
                    className="badge-err"
                >
                    {internalError}
                </label>
            ) : null}

            {/* DISPLAY SUGESSTION HOLDER */}
            {autoSuggestion.length > 0 ? (
                <div
                    ref={suggestionRef}
                    className="auto-suggestion"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        maxHeight: 300,
                        overflowY: 'auto',
                        marginTop: -2,
                        background: '#fff'
                    }}
                >
                    <ul>
                        {autoSuggestion.map((i, idx) => {
                            return (
                                <li
                                    onClick={() => {
                                        props.onSuggesstionSelect(i);
                                    }}
                                    key={idx}
                                >
                                    {' '}
                                    {i.title}{' '}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

Input.defaultProps = {
    value: '',
    className: '',
    type: 'text',
    error: null,
    limit: 0,
    autoSuggestion: [],
    onSuggesstionSelect: () => {},
    onClickOutSide: () => {},
    disabled: false,
    isRadius: true
};

Input.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    limit: PropTypes.number,
    autoSuggestion: PropTypes.array,
    onSuggesstionSelect: PropTypes.func,
    disabled: PropTypes.bool,
    isRadius: PropTypes.bool
};

/* RADIO INPUT */
export const Radio = (props) => {
    const { name, title, checked } = props;
    return (
        <label>
            <input
                className="with-gap"
                name={name}
                type="radio"
                defaultChecked={checked}
                onChange={(e) => {
                    props.onChange(e);
                }}
            />
            <span>{title}</span>
        </label>
    );
};

Radio.defaultProps = {
    title: '',
    name: '',
    checked: false,
    onChange: () => {}
};

Radio.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func
};

/* SELECT 
data : [{code, value}]
*/
export const Select = (props) => {
    const { title, data, style, defaultValue, id, error, isTextHeader, className = '' } = props;

    return (
        <>
            <select
                id={id}
                className={'no-border pl-15 pr-15 bg-grey ' + className}
                onChange={(e) => {
                    props.onChange(e);
                }}
                style={{ ...style }}
                value={defaultValue}
            >
                {isTextHeader ? <option value="">{title}</option> : null}

                {data.map((item, index) => {
                    return (
                        <option key={index} value={item.code}>
                            {item.value}
                        </option>
                    );
                })}
            </select>
            {error !== '' ? (
                <label
                    style={{
                        position: 'absolute',
                        zIndex: 1003,
                        height: 16,
                        lineHeight: '14px',
                        marginTop: -9
                    }}
                    className="badge-err"
                >
                    {error}
                </label>
            ) : null}
        </>
    );
};

Select.defaultProps = {
    id: UUID(),
    defaultValue: '',
    error: '',
    title: 'Please choose',
    style: {},
    data: [],
    onChange: () => {}
};

Select.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    error: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    data: PropTypes.array,
    onChange: PropTypes.func
};

/* TEXTAREA*/
export const TextArea = (props) => {
    const { style, placeholder, id, error } = props;
    return (
        <>
            <textarea
                id={id}
                placeholder={placeholder}
                rows={4}
                col={2}
                className="form-control"
                style={{ width: '100%', fontFamily: 'Roboto', ...style }}
                onChange={(e) => {
                    props.onChange(e);
                }}
                defaultValue={props.defaultValue}
            >
                {props.children}
            </textarea>
            {error !== '' ? (
                <label
                    style={{
                        position: 'absolute',
                        zIndex: 1003,
                        height: 16,
                        lineHeight: '14px',
                        marginTop: -9,
                        left: 20,
                        paddingLeft: 4
                    }}
                    className="badge-err"
                >
                    {error}
                </label>
            ) : null}
        </>
    );
};

TextArea.defaultProps = {
    id: '',
    value: '',
    style: {},
    placeholder: '',
    onChange: () => {},
    error: ''
};

TextArea.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.string
};

export const OryDatePicker = (props) => {
    const { startDate, onChange } = props;

    return (
        <DatePicker
            style={{ width: '100%' }}
            className="form-control"
            selected={new Date(startDate === '' ? orytonTime.thisDate() : startDate)}
            onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
            dateFormat="dd.MM.yyyy"
        />
    );
};

OryDatePicker.defaultProps = {
    startDate: orytonTime.thisDate(),
    onChange: () => {}
};

OryDatePicker.propTypes = {
    startDate: PropTypes.string,
    onChange: PropTypes.func
};

export const Button = (props) => {
    const { name, type } = props;
    return (
        <button type={type} className={`btn btn-normal radius-left radius-right ${name}`} {...props}>
            {props.children}
        </button>
    );
};

Button.defaultProps = {
    name: '',
    type: 'button'
};

export const ButtonGroup = (props) => {
    const { style, preIcon, preFix, suffix, className } = props;
    return (
        <div role="group" className={`btn-group ${className}`} style={{ ...style }}>
            {preIcon ? (
                <a
                    style={{
                        border: '1px solid rgba(0,0,0,0.1)',
                        background: '#fff',
                        borderRight: 0,
                        textAlign: 'center',
                        width: 'auto',
                        paddingLeft: 10,
                        paddingRight: 10,

                        paddingTop: 1
                    }}
                >
                    <i className={preIcon} />
                </a>
            ) : null}

            {preFix ? (
                <a
                    style={{
                        border: '0px solid #f0f0f0',
                        background: '#f0f0f0',
                        textAlign: 'center',
                        width: 'auto',
                        borderRight: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 1
                    }}
                >
                    {preFix}
                </a>
            ) : null}
            {props.children}

            {suffix ? (
                <a
                    style={{
                        background: '#f0f0f0',
                        border: '0px solid rgba(0,0,0,0.1)',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 7,
                        textAlign: 'center',
                        borderLeft: 0
                    }}
                >
                    {suffix}
                </a>
            ) : null}
        </div>
    );
};

ButtonGroup.defaultProps = {
    style: {},
    preIcon: '',
    preFix: '',
    suffix: '',
    className: ''
};

ButtonGroup.propTypes = {
    style: PropTypes.object,
    preIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    preFix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string
};

export const LineDesc = (props) => {
    const { display, style } = props;
    return (
        <div
            {...props}
            style={{
                marginTop: 0,
                fontSize: 12,
                lineHeight: '18px',
                color: '#999',
                display: display ? 'block' : 'none',
                ...style
            }}
        >
            {props.children}
        </div>
    );
};

LineDesc.defaultProps = {
    display: true,
    style: {}
};
LineDesc.propTypes = {
    display: PropTypes.bool,
    style: PropTypes.string
};

// CHECKBOX BASIC
export const CheckBoxBasic = (props) => {
    const { checked, title, style } = props;
    const icon = checked ? `check_box` : `check_box_outline_blank`;
    return (
        <label style={{ display: 'flex', ...style }} onClick={props.onChange}>
            <OryIcon style={{ marginTop: 4, marginRight: 5 }} icon={icon} /> {title}
        </label>
    );
};

CheckBoxBasic.propTypes = {
    checked: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func
};
CheckBoxBasic.defaultProps = {
    checked: false,
    style: {},
    onChange: () => {}
};
// END CHECKBOX BASIC

// INPUT SEARCH
export const InputSearch = ({ onSearch = () => {}, onChange = () => {}, placeholder = null, style = {} }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        key: ''
    });

    const _onCloseSearch = () => {
        setState((prev) => {
            return {
                ...prev,
                key: ''
            };
        });
        onSearch('');
    };

    return (
        <form
            style={{ display: 'contents', position: 'relative' }}
            onSubmit={(e) => {
                e.preventDefault();
                onSearch(state.key);
            }}
        >
            <ButtonGroup
                suffix={
                    <a>
                        <i className="fa fa-search text-main" />
                    </a>
                }
                style={{ width: '100%', ...style }}
            >
                <input
                    autoComplete="off"
                    value={state.key}
                    id="Key"
                    placeholder={placeholder || t('Search')}
                    onChange={(e) => {
                        setState((prev) => {
                            return {
                                ...prev,
                                key: e.target.value
                            };
                        });
                        onChange(e.target.value);
                    }}
                    type="text"
                    className="form-control inline-block"
                    style={{ borderTopRightRadius: 0, width: '100%' }}
                />
                <a
                    onClick={_onCloseSearch}
                    className="cursor"
                    style={{
                        position: 'absolute',
                        display: state.key === '' ? 'none' : 'block',
                        right: 50,
                        top: 6
                    }}
                >
                    <i className="fa fa-close"></i>
                </a>
            </ButtonGroup>
        </form>
    );
};
// END SEARCH

export const ButtonDropDown = ({ title = 'Button', className = '' }) => {
    return (
        <ButtonGroup className={className}>
            <button className="btn-sm pt-5 pb-5">{title}</button>
            <button className="btn-sm pt-5 pb-5">
                <i className="fa fa-angle-down"></i>
            </button>
        </ButtonGroup>
    );
};

/**
 * BUTTON OPTIONS
 */
export const ButtonOption = ({ list = [], defaultValue = '', onChange = () => {} }) => {
    const _click = (item) => {
        onChange(item);
    };

    return (
        <ButtonGroup className="width-100">
            {list.map((item, index) => {
                return (
                    <button
                        onClick={() => {
                            _click(item);
                        }}
                        key={index}
                        className={`  ${item.code == defaultValue ? 'btn  bg-green' : 'btn-sm'} `}
                        style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            height: 33,
                            fontSize: 12,
                            lineHeight: '18px',
                            width: 100 / list.length + '%'
                        }}
                    >
                        {item.code == defaultValue ? <i className="fa fa-check-square mr-5"></i> : null} {item.value}
                    </button>
                );
            })}
        </ButtonGroup>
    );
};

export const InputLabel = ({ value = '', className = '' }) => {
    return <input value={value} className={'input-label text-none ' + className} disabled title={value} />;
};
