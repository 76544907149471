

/**
 * GET THE LIST DATE OF MONTH USING FOR SELECT COMPONENT
 * @returns {Array} [{code, value}]
 */
const getListDateOfMonth = ()=>{

    let arr = [];
    for(let i=1; i<32; i++){

        const value = i < 10 ? '0'+i : i;
        arr.push({
            code:value,
            value: value
        })
    }

    return arr ;
}

export default getListDateOfMonth;