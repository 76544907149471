
import APP_CONFIG from '../config/app.json';

const {MONTHS} = APP_CONFIG ;

const date = new Date();
const CUR_MONTH = parseInt(date.getMonth()) + 1;  

/**
 * 
 * @returns { array } [{ id, value, active }] auto select prev month 
 */
function createListMonth(){

    let arr = [] ;

    MONTHS.map((item, index)=>{
        
        arr.push({
            code: index + 1,
            value: item,
            active: CUR_MONTH !== 1 ? CUR_MONTH === index + 2 : CUR_MONTH === 12 ? true : false  
        })
    })

    return arr 
}

export default createListMonth ; 
