/**
 * ROUTER HELPDER : SINGLETON 
 */
export const RouterHelper = (() => {
    let instance;
    let ROUTE;

    function init(route) {
        ROUTE = route;

        /**
         *
         * @param {json} path {Page:'', Action:''} // Page === ''=> curPage
         * @param {*} data
         */
        function navigate(path, data) {
            try {
                const { company, page } = getRoute();
                let { Page, Action } = path;

                Action = Action.indexOf("?") > -1 ? Action : "/" + Action;
                Page = Page === "" ? page : Page;
                Page = Page.indexOf("?") > -1 ? Page.split("?")[0] : Page;

                const uri = "/" + company + "/" + Page + Action;
                ROUTE.history.push(uri, data);
            } catch (err) {
                alert("something wrong with RouterHelper " + err);
            }
        }

        function parseUrl(url = "") {
            var regex = /[?&]([^=#]+)=([^&#]*)/g,
                url,
                params = {},
                match;
            while ((match = regex.exec(url))) {
                params[match[1]] = match[2];
            }

            return params;
        }

        /**
         *
         * @returns {JSON} {...params}
         */
        function getParams() {
            const uri = window.location.href;

            return parseUrl(uri);
        }

        function getRoute() {
            const uri = window.location.href;
            const company = uri.split("/")[3];
            const page = uri.split("/")[4];

            return {
                company,
                page,
            };
        }

        /**
         *
         * @param {string} URL
         * @returns {object} { param1, param2,..}
         */
        function getUrlParams(URL = "") {
            try {
                const test = URL.split("?")[1].split("&");

                let obj = {};
                test.forEach((item) => {
                    const a = item.split("=");
                    obj[a[0]] = a[1];
                });

                return obj;
            } catch (err) {
                return;
            }
        }

        return {
            navigate,
            getRoute,
            getUrlParams,

            parseUrl,
            getParams,
        };
    }

    return {
        start(route) {
            if (!instance) {
                instance = new init(route);
            }

            return instance;
        },
    };
})();

/**
 * usage :
 * const routeHelper = RouteHelper(props)
 * routeHelper.navigate({Page:'', Action:''})
 */
