import { server } from '../config/server';
import axios from 'axios';

/**
 *
 * @param {string} uri
 * @param {object} data
 * @returns { JSON }
 */

const myType = {
    status: 'hook-err', // hook-err || success
    data: [],
    msg: ''
};

function doGet(uri = '', isNeighbor = true) {
    const ret = { ...myType };

    return new Promise((resolve, reject) => {
        if (uri !== '') {
            const url = isNeighbor ? server.base() + uri : uri;

            axios
                .get(url, isNeighbor ? server.setHeader() : {})
                .then((res) => {
                    ret.status = 'success';
                    resolve(Object.assign(ret, res.data));
                })
                .catch((err) => {
                    reject(err);
                    resolve({ ...ret, msg: err });
                });
        } else {
            resolve({ ...ret, msg: 'Missed uri' });
        }
    });
}

export default doGet;
