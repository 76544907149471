import singCompany from '../models/singCompany';
import { singUser } from '../models/singUser';
import { localDB } from '../models/localData';

import doGetWithBearer from '../ultils/doGetWithBearer';
const { AbtractHandler } = require('./AbtractHandler');

export class LayoutHandler extends AbtractHandler {
    constructor() {
        super();

        this.attempCount = 0;

        if (!LayoutHandler._instance) LayoutHandler._instance = this;
        return LayoutHandler._instance;
    }

    stateHeaderBuilder() {
        return {
            isOpenForm: false,
            selectOptions: null,
            paddingBottom: 0,
            curCompany: singCompany.getInfo(),
            userInfo: singUser.getInfo(),

            curDropDown: null
        };
    }
    stateBuilder() {
        return {
            companyInfo: singCompany.getInfo(),
            isOpenForm: false,
            IsAccess: singUser.getInfo()['RoleId'] > 1,
            listCompany: []
        };
    }

    async boot(companyRoute) {
        const { state } = this.stateHelper;

        if (state.IsAccess) {
            // LOAD ALL COMPANY HERE
            const listCompany = await singCompany.getAllCompany();
            this.stateHelper.whereStateChange('listCompany', listCompany);

            if (companyRoute) {
                const info = listCompany.find((item) => item.label.toLowerCase() === companyRoute.replace(/-/g, ' '));

                if (info) {
                    const compOrgId = info.value === '1111111111' || info.value === '222222222' ? 989261703 : info.value;
                    const URL = '/apix/company/getFull?orgId=' + compOrgId + '&id=' + info.id;

                    doGetWithBearer(URL).then((res) => {
                        if (res.name === 'success') {
                            const newData = res.data;
                            this.stateHelper.whereStateChange('companyInfo', newData);
                        } else {
                            //console.log(res);
                            if (this.attempCount < 5) {
                                //window.location.reload();
                                this.boot(companyRoute);
                            }
                        }
                    });
                }
            } else {
                // OPEN FORM CHOOSE COMPANY
                this.stateHelper.toggleModal('isOpenForm');
            }
        }
    }

    accessCompany(data = null) {
        if (!data) return;

        const page = 'dashboard';

        const compOrgId = data.value === '1111111111' || data.value === '222222222' ? 989261703 : data.value;

        const URL = '/apix/company/getFull?orgId=' + compOrgId + '&id=' + data.id;

        doGetWithBearer(URL).then(async (res) => {
            if (res.name === 'success') {
                const newData = res.data;
                // THEN SAVE DATA
                localDB.saveOrytonCompany({
                    id: newData.CompanyId,
                    label: newData.companyName,
                    value: newData.OrganisationNumber,
                    ...newData,
                    CompanyId: data.id
                });

                const path = data.label.toLowerCase().replace(/ /g, '-');
                window.location = '/' + path + '/' + page;
            } else {
                if (this.attempCount < 5) {
                    const resCheck = await singUser.updateCheckLogin();
                    resCheck ? this.accessCompany(data) : window.nil();

                    this.attempCount += 1;
                }
            }
        });
    }

    onChangeLang(code = 'en') {
        try {
            const BOARD = { gb: 'en', no: 'no', vn: 'vi' };
            const lang = BOARD[code];

            singUser.changeLang(lang).then((resData) => {
                if (resData.name === 'success') {
                    localDB.myOwn.setKey('Language', lang);
                    window.location.reload();
                }
            });
        } catch {}
    }

    onChangeService(item) {
        try {
            if (item['development'] !== '' && item['production'] !== '') {
                window.location.href = item[process.env.NODE_ENV];
            }
        } catch (err) {}
    }

    onProfileItem(code) {
        const actions = {
            profile: () => {
                singUser.myProfile();
            },
            logout: () => {
                singUser.logout();
            },
            add_company: () => {
                alert('add company');
            }
        };

        try {
            actions[code]();
        } catch {}
    }
}
