
import pic404 from "../../assets/404.png";
import pic500 from "../../assets/500.PNG" ; 


const Box404 = ({message='', type = 400})=>{

    return (
        <div style={{ width:900, margin:'auto', marginTop:'5%', background:'#fff', padding:20, border:'1px solid rgba(0,0,0,0.2)', textAlign:'center'}}>
            <img src={ type === 400 ? pic404 : pic500 } />
            <div>
                {message}
            </div>
        </div>
    )
}

export default Box404 ; 