import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {useWindowSize} from "./../how/useWindowSize";
import OrytonIcon from "./OrytonIcon";

/**
 *
 * @param {*} props Object
 * @returns React element
 */

const defaultProps = {
    with: 500,
    isOpen: false,
    title: "Modal",

    onShowHelp: () => {},
    onClose: () => {},
    submitText: "Okay",
    onSubmit: () => {},

    isFooter: true,
    isHeader: true,
    isFullScreen: true,
    style: {},
    enableClickOutSideClose: true,
    top: "3%",
};
const propTypes = {
    isOpen: PropTypes.bool,
    width: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    onShowHelp: PropTypes.func,
    onClose: PropTypes.func,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func,

    children: PropTypes.node,
    isFooter: PropTypes.bool,
    isHeader: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    style: PropTypes.object,
    enableClickOutSideClose: PropTypes.bool,
    top: PropTypes.string,
};
const Modal = (props) => {
    const { t } = useTranslation();
    const {
        width,
        isOpen,
        title,
        children,
        submitText,
        isFooter,
        enableClickOutSideClose,
        top,
        isFullScreen,
    } = props;

    const windowSize = useWindowSize();

    const myRef = useRef();

    useEffect(() => {
        if (isOpen) {
            document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName("body")[0].style.overflowY = "auto";
        }
    }, [isOpen]);

    useEffect(() => {
        if (enableClickOutSideClose && isFooter) {
            try {
                const handleClickOutside = (e) => {
                    if (!myRef.current.contains(e.target)) {
                        if (isOpen) {
                            props.onClose();
                        }
                    }
                };

                document.addEventListener("mousedown", handleClickOutside);
                return () =>
                    document.removeEventListener(
                        "mousedown",
                        handleClickOutside
                    );
            } catch (err) {}
        }
    });

    const display = {
        zIndex: isOpen ? 10000 : 0,
        background: isOpen ? "rgb(0,0,0,0.5)" : "none",
    };
    return (
        <div
            style={{
                position: "fixed",
                height: isOpen ? "100%" : 0,
                width: isOpen ? "100%" : 0,
                top: 0,
                left: 0,
                ...display,
                zIndex: 10000,
            }}
        >
            <div
                ref={myRef}
                className={`modal ${isFullScreen ? "isFullScreen" : ""}`}
                style={{
                    display: isOpen ? "block" : "none",
                    top: top,
                    maxWidth:
                        windowSize.width >= width ? width : windowSize.width,
                    width: "100%",
                    maxHeight: "100vh",

                    overflowY: "hidden",

                    background: "#fff",
                    borderRadius: 6,
                }}
            >
                <div
                    className={`modal-content ${isFooter ? "isFooter" : ""}`}
                    style={{ background: "#fff", padding: 0 }}
                >
                    {/* MODAL TITLE */}
                    {props.isHeader ? (
                        <div
                            style={{
                                padding: 15,
                                borderBottom: "1px solid #eee",
                            }}
                            className="display-flex justify-content-between"
                        >
                            <div className="text-blue font-22">{title}</div>
                            <div className="nowrap">
                                <a
                                    onClick={props.onShowHelp}
                                    className="cursor mr-10"
                                >
                                    <OrytonIcon size={20} icon="school" />
                                </a>
                                <a className="cursor" onClick={props.onClose}>
                                    <OrytonIcon icon="close" />
                                </a>
                            </div>
                        </div>
                    ) : null}

                    {/** MODAL CONTENT */}
                    <div
                        style={{
                            maxHeight: isFooter
                                ? "calc(100dvh - 120px)"
                                : "calc(100dvh - 60px)",
                            height: "100%",
                            overflowY: "auto",
                            padding: 20,
                        }}
                    >
                        <div
                            style={{
                                ...props.style,
                            }}
                        >
                            {children}
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                {isFooter ? (
                    <div
                        className="modal-footer"
                        style={{
                            borderTop: "1px solid #f0f0f0",
                            background: "#fff",
                            padding: "0.5rem 1rem",
                        }}
                    >
                        {/* LEFT SIDE */}
                        <div
                            className="display-flex justify-content-start"
                            style={{ lineHeight: "unset" }}
                        >
                            <span
                                className={`form-${title
                                    .toLowerCase()
                                    .replace(/ /g, "-")}`}
                            >
                                <span
                                    className="ml-10 left-align"
                                    id="form-err"
                                ></span>
                            </span>
                        </div>

                        {/* RIGHT SIDE */}
                        <div
                            role="group"
                            style={{
                                lineHeight: "48px",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                type="button"
                                style={{ fontWeight: "500" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.onClose();
                                }}
                                className="btn btn-normal mr-15 pl-20 pr-20"
                            >
                                <i className="fa fa-chevron-circle-left mr-5"></i>
                                {t("Cancel")}
                            </button>

                            <button
                                disabled={props.submitDisabled}
                                onClick={props.onSubmit}
                                type="button"
                                className="btn-ubuntu-ok  btn btn-secondary radius-right pl-30 pr-30"
                            >
                                {" "}
                                <i className="fa fa-chevron-circle-right mr-5" />{" "}
                                {t(submitText)}
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
