// FOR SAVE TIME LOADING MODULE

import SETTINGS from "../config/settings.json";
import moment from "moment";
Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export const orytonTime = (() => {
    const { MONTHS } = SETTINGS;

    let instance;

    const DATE = new Date();

    function init() {
        function month2NumByVal(strMonth) {
            try {
                return (
                    parseInt(
                        MONTHS.findIndex((item) => item.value == strMonth)
                    ) + 1
                );
            } catch {
                return 0;
            }
        }

        function MM2MMMM(strNum) {
            const d = thisYear() + "-" + strNum + "-01";
            return moment(d).format("MMMM");
        }
        /**
         *
         * @param {string} MMMM
         * @returns {string} MM
         */
        function MMMM2MM(str) {
            const num =
                MONTHS.findIndex((item) => item.code === str.toLowerCase()) + 1;
            return num > 9 ? num : "0" + num;
        }

        function getListTime() {
            const max = 48;
            let arr = [];
            for (let i = 0; i < max; i++) {
                const test = (i / 2).toString();
                const formatText = (str) => {
                    return parseInt(str) < 10 ? "0" + str : str;
                };

                const hh =
                    test.indexOf(".") > -1
                        ? formatText(
                              parseInt(test) < 10
                                  ? test.substr(0, 1)
                                  : test.substr(0, 2)
                          )
                        : formatText(test);
                const mm = test.indexOf(".") > -1 ? "30" : "00";
                arr.push({
                    code: hh + ":" + mm,
                    value: hh + ":" + mm,
                });
            }

            return arr;
        }

        function calculateDate(strD1, strD2) {
            const d1 = new Date(strD1);
            const d2 = new Date(strD2);

            const diff = Math.floor(d2.getTime() - d1.getTime());

            const day = 1000 * 60 * 60 * 24;

            const days = Math.floor(diff / day);
            const months = Math.floor(days / 31);
            const years = Math.floor(months / 12);

            return {
                days,
                months,
                years,
            };
        }
        /**
         * convert string datetime -> timestamp
         * @param {string} strDate
         * @returns {number} unix time
         */
        function toUnixTime(strDate) {
            strDate = strDate.replace(/-/g, "/");
            return new Date(strDate) / 1000;
        }

        /**
         * convert unix time -> datetime
         * @param {number} UNIX_timestamp
         * @returns {string} datetime
         */
        function toDateTime(UNIX_timestamp) {
            const a = new Date(UNIX_timestamp * 1000);

            const year = a.getFullYear();
            const month =
                a.getMonth() + 1 < 10
                    ? "0" + (a.getMonth() + 1)
                    : a.getMonth() + 1;
            const date =
                parseInt(a.getDate(), 10) < 10
                    ? "0" + a.getDate()
                    : a.getDate();
            const hour =
                parseInt(a.getHours(), 10) < 10
                    ? "0" + a.getHours()
                    : a.getHours();
            const min =
                parseInt(a.getMinutes(), 10) < 10
                    ? "0" + a.getMinutes()
                    : a.getMinutes();
            const sec =
                parseInt(a.getSeconds(), 10) < 10
                    ? "0" + a.getSeconds()
                    : a.getSeconds();

            return (
                year +
                "-" +
                month +
                "-" +
                date +
                " " +
                hour +
                ":" +
                min +
                ":" +
                sec
            );
        }

        /**
         * ADD NUMBER TO DATETIME
         * @param {string} date
         * @param {number} days
         * @returns {datetime}
         */
        function addDays(strDate, days) {
            const date = new Date(strDate);
            return moment(date.setDate(date.getDate() + days)).format(
                "YYYY-MM-DD"
            );
        }

        function thisYear() {
            return DATE.getFullYear();
        }
        function thisMonth() {
            var month = DATE.getMonth() + 1;
            month = month < 10 ? "0" + month : month;

            return month;
        }
        function thisWeek() {
            let num = DATE.getWeek();
            num = num < 10 ? "0" + num : num;

            return num;
        }
        function thisDay() {
            return DATE.getDate();
        }

        function thisDate() {
            return thisYear() + "-" + thisMonth() + "-" + thisDay();
        }

        function thisTime() {
            let h = DATE.getHours();
            h = parseInt(h) < 10 ? "0" + h : h;
            let m = DATE.getMinutes();
            m = parseInt(m) < 10 ? "0" + m : m;
            let ss = DATE.getSeconds();
            ss = parseInt(ss) < 10 ? "0" + ss : ss;

            const msgTime = h + ":" + m + ":" + ss;
            return msgTime;
        }

        function thisFullDate(isTimestamp = false) {
            const curDate = thisDate() + " " + thisTime();
            return isTimestamp ? toUnixTime(curDate) : curDate;
        }

        function headYear() {
            return thisYear() + "-01-01";
        }

        function endYear(){
            return thisYear()+'-12-'+ lastDayInMonth(12,thisYear());
        }

        function headMonth() {
            return thisYear() + "-" + thisMonth() + "-01";
        }

        // format YYYY-MM-DD
        function YMD(strDate) {
            return moment(strDate).format("YYYY-MM-DD");
        }
        function MMDDYY(strDate) {
            return moment(strDate).format("MM.DD.YYYY");
        }

        function MMDDYYFull(strDate) {
            return moment(strDate).format("MM.DD.YYYY hh:mm:ss");
        }

        function lastDayInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        }


        /**
         * 
         * @param {number} year 
         * @returns {Array} [{from, to}]
         */
        function getListTermin(year = 2021) {
            const arrTermin = [1, 2, 3, 4, 5, 6];

            const arr = arrTermin.map((item) => {
                const monthFrom =
                    item * 2 - 1 < 10
                        ? "0" + (item * 2 - 1)
                        : (item * 2 - 1).toString();
                const monthTo =
                    item * 2 < 10 ? "0" + item * 2 : (item * 2).toString();

                return {
                    from: year + "-" + monthFrom + "-01 01:00:00",
                    to:
                        year +
                        "-" +
                        monthTo +
                        "-" +
                        lastDayInMonth(monthTo, year) +
                        " 23:59:00",
                };
            });

            return arr;
        }


        /**
         * 
         * @returns {Array} [{code, value}]
         */
        function getListYear() {
            const CUR_YEAR = thisYear();
            const FROM_YEAR = CUR_YEAR - 3;

            const arr = [];

            for (let i = FROM_YEAR; i <= CUR_YEAR; i++) {
                arr.push({
                    code: i,
                    value: i,
                });
            }

            return arr;
        }

        return {
            month2NumByVal,
            toUnixTime,
            toDateTime,
            addDays,
            thisYear,
            thisMonth,
            thisWeek,
            thisDay,
            thisDate,
            thisTime,
            headMonth,
            headYear,
            thisFullDate,
            calculateDate,
            MMMM2MM,
            moment,
            getListTime,
            YMD,
            MM2MMMM,
            MMDDYY,
            MMDDYYFull,
            lastDayInMonth,
            getListTermin,
            getListYear,
            endYear
        };
    }

    return {
        start() {
            if (!instance) {
                instance = new init();
            }

            return instance;
        },
    };
})().start();
