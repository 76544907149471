//WHO

import { MyStateHelper } from '../modules/MyStateHelper';

// HOW
import GetCurrentRoute from '../ultils/getCurrentRoute';

import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

import { HeaderLeftSide } from './Components/Header.Left';
import { HeaderRightSide } from './Components/Header.Right';

// CONTEXT
import { LayoutContext } from '.';

const defaultProps = {
    companyInfo: null,
    onOpenModal: () => {},
    layoutHelper: Object(),
    _toggleLeftSide: () => {}
};
const propTypes = {
    companyInfo: PropTypes.object,
    onOpenModal: PropTypes.func,
    layoutHelper: PropTypes.object,
    _toggleLeftSide: PropTypes.func
};

const stateHelper = new MyStateHelper();
export const Header = (props) => {
    const layoutHelper = useContext(LayoutContext);
    const { companyInfo } = props;
    const { ctrl } = layoutHelper;

    // INIT DATA ;
    const [state, setState] = useState(ctrl.stateHeaderBuilder());
    stateHelper.set(state, setState);

    return (
        <header className="page-topbar" id="header">
            <div className="navbar navbar-fixed">
                <nav
                    className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light box-shadow-none"
                    style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}
                >
                    <div className="nav-wrapper">
                        {/* LEFT NAV */}
                        <HeaderLeftSide _toggleLeftSide={props._toggleLeftSide} />
                        {/* NAV RIGHT  */}
                        <HeaderRightSide companyInfo={companyInfo} stateHelper={stateHelper} />
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.defaultProps = defaultProps;
Header.propTypes = propTypes;
