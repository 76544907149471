

const formatBankNumber = (text) => {

    if(!text) return 
    
    return (
        text.substring(0, 4) +
        "." +
        text.substring(4, 6) +
        "." +
        text.substring(6, 11)
    );
}

export default formatBankNumber ; 