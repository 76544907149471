import _ from 'lodash';
import APP_CONFIG from '../config/app.json';
import { singUser } from '../models/singUser';

import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useCallback, useMemo } from 'react';

import Icon from '../components/Icon';
import LOGO from '../assets/logo.png';
import UseClickOutSide from '../hooks/useClickOutSide';
import { useWindowSize } from './../how/useWindowSize';
import { UUID } from '../ultils';

const findActive = _.memoize((json) => {
    return _.cloneDeep(APP_CONFIG?.nav).map((item) => {
        return {
            ...item,
            active: json.active !== undefined ? (item.path === json.path ? !item.active : false) : item.path === json.path ? true : false
        };
    });
});

const findOpen = (arr, json) => {
    return _.cloneDeep(arr).map((item) => {
        if (item.subs) {
            item.subs.map((subItem) => {
                return {
                    ...subItem,
                    isOpen: subItem.path === json.path ? !subItem.isOpen : subItem.isOpen
                };
            });
        }

        return item;
    });
};

const RootItemNav = React.memo(({ isSubItem = false, isActive = '', item = {}, onOpenSubItem = () => {} }) => {
    const { t } = useTranslation();
    const { company } = useParams();

    return isSubItem ? (
        <a className={`waves-effect waves-cyan  ${isActive}`} onClick={onOpenSubItem}>
            <Icon name={item?.icon} color={isActive ? '#fff' : '#162C41'} size={15} />
            <span
                className="ml-20 menu-title font-16 "
                style={{
                    fontWeight: '500'
                }}
            >
                <span>{t(item?.name)}</span>

                <span
                    style={{
                        position: 'absolute',
                        right: 10,
                        marginTop: -3
                    }}
                >
                    <Icon
                        size={'3.5pt'}
                        color={isActive === 'active' ? '#fff' : '#162C41'}
                        name={isActive === 'active' ? 'arrow_up' : 'arrow_down'}
                    />
                </span>
            </span>
        </a>
    ) : (
        <Link
            key={UUID()}
            className={`waves-effect waves-cyan ` + isActive}
            to={isSubItem ? '' : '/' + company + item?.path}
            onClick={onOpenSubItem}
        >
            <Icon name={item?.icon} color={isActive ? '#fff' : '#162C41'} size={15} />

            <span
                className="ml-20 menu-title font-16"
                style={{
                    fontWeight: '500',
                    display: 'inline-flex'
                }}
            >
                <span>{t(item?.name)}</span>
            </span>
        </Link>
    );
});
RootItemNav.displayName = 'RootItemNav';

const SubItemNav = React.memo(({ subItem = {}, onOpenDoubleSub = () => {}, onToggleSideBar = () => {} }) => {
    const isOpenIcon = subItem.isOpen ? 'fa fa-minus-circle' : 'fa fa-plus-circle';
    const { company } = useParams();
    const location = useLocation();
    const { pathname } = location;

    const isActive = '/' + company + subItem.path === pathname ? 'active' : '';
    const isDoubleSubItem = subItem.subs;

    if (!isDoubleSubItem) {
        if (!subItem.display) return null;

        return (
            <Link to={'/' + company + subItem.path} onClick={onToggleSideBar}>
                <span
                    style={{
                        marginLeft: 35
                    }}
                    className={isActive}
                >
                    {subItem.name}
                </span>
            </Link>
        );
    } else {
        return (
            <a className={`waves-effect waves-cyan`} onClick={onOpenDoubleSub}>
                <span
                    style={{
                        marginLeft: 35
                    }}
                    className={subItem.isOpen ? 'active' : ''}
                >
                    {subItem.name}
                </span>
                <span
                    className={subItem.isOpen ? 'active' : ''}
                    style={{
                        position: 'absolute',
                        right: -15
                    }}
                >
                    <i className={isOpenIcon}></i>
                </span>
            </a>
        );
    }
});
SubItemNav.displayName = 'SubItemNav';

export const LeftAside = React.memo(() => {
    const { nav } = APP_CONFIG;
    const { t } = useTranslation();
    const { company } = useParams();
    const windowSize = useWindowSize();

    const location = useLocation();

    const { pathname } = location;
    const refSideBar = useRef();

    const [state, setState] = useState(nav);
    const [localState, setLocalState] = useState({
        isOpen: windowSize.width > 992
    });

    UseClickOutSide(refSideBar, setLocalState);

    useEffect(() => {
        window.addEventListener('resize', _onResizing);

        return () => {
            window.removeEventListener('resize', _onResizing);
        };
    }, []);

    const _onResizing = useCallback(
        (e) => {
            if (windowSize.width <= 992 && localState.isOpen) {
                setLocalState((prev) => ({
                    ...prev,
                    isOpen: false
                }));
            }
            if (windowSize.width > 992 && !localState.isOpen) {
                setLocalState((prev) => ({
                    ...prev,
                    isOpen: true
                }));
            }
        },
        [windowSize.width, localState.isOpen]
    );

    const _onToggleSideBar = useCallback(() => {
        setLocalState({
            ...localState,
            isOpen: !localState.isOpen
        });
    }, []);

    const _openSubItem = (json) => {
        setState(findActive(json));
    };

    const _openDoubleSub = (json = {}) => {
        setState(findOpen(state, json));
    };

    const userInfo = useMemo(() => {
        return singUser.getInfo();
    }, [singUser.getInfo()]);

    if (!userInfo) return null;

    return (
        <aside className="sidenav-main nav-expanded nav-lock nav-collapsible  sidenav-active-rounded">
            {/* MAIN MENU */}
            <ul
                ref={refSideBar}
                className={`pt-10 sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow box-shadow-none ${
                    localState.isOpen ? 'show' : ''
                }`}
                data-menu="menu-navigation"
                data-collapsible="accordion"
                style={{
                    borderRight: '1px solid rgba(0,0,0,0.2)',
                    top: 0,
                    zIndex: 9999,
                    overflow: 'hidden auto',
                    maxHeight: '100vh'
                }}
            >
                <li
                    className="bold item"
                    style={{
                        height: 62.5
                    }}
                >
                    {/* BRAND NAME */}
                    <div className="brand-sidebar" style={{ borderRight: '1px solid rgba(0,0,0,0.2)' }}>
                        <h1 className="logo-wrapper">
                            <Link className="brand-logo darken-1" to={'/' + company + '/dashboard'} onClick={_onToggleSideBar}>
                                <img style={{ height: 27, marginLeft: '30%' }} src={LOGO} alt="" />
                            </Link>
                        </h1>
                    </div>
                </li>
                {state.slice(2, nav.length).map((item, index) => {
                    //let isActive = pathname === item.path ? "active" : "";
                    const isSubItem = item.subs ? true : false;
                    let isActive = isSubItem ? item.active : pathname.includes(item.path);
                    isActive = isActive ? 'active' : '';

                    isActive = pathname.includes(company + '/' + item.path) ? 'active' : isActive;

                    if (item.display) {
                        return (
                            <li name={pathname} key={index} className="bold item">
                                <RootItemNav
                                    isSubItem={isSubItem}
                                    isActive={isActive}
                                    item={item}
                                    onOpenSubItem={() => _openSubItem(item)}
                                />

                                {isSubItem ? (
                                    <div id={'item-' + item.path} className={`collapsible-body ${isActive ? 'show' : 'none'}`}>
                                        <ul className="collapsible-sub">
                                            {item.subs.map((subItem) => {
                                                return (
                                                    <li
                                                        key={UUID()}
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        {/* SUB ITEM  */}
                                                        <SubItemNav
                                                            onOpenDoubleSub={() => _openDoubleSub(subItem)}
                                                            onToggleSideBar={_onToggleSideBar}
                                                            subItem={subItem}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ) : null}
                            </li>
                        );
                    }
                })}
            </ul>
        </aside>
    );
});

LeftAside.displayName = 'LeftAside';
