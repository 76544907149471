import loadable from "@loadable/component";
import { Loading } from './components/Oryton';

const Dashboard = loadable(() => import("./pages/Dashboard"),{
    fallback:<Loading />
});

/**
 * SALES
 */
const Invoice = loadable(()=> import("./pages/Sales/Invoice"),{
    fallback:<Loading />
});

const OverView = loadable(()=> import("./pages/Sales/OverView"),{
    fallback:<Loading />
});

const Reports = loadable(()=> import("./pages/Sales/Reports"),{
    fallback:<Loading />
});


const OtherSales = loadable(()=> import("./pages/Sales/OtherSales"),{
    fallback:<Loading />
});

/** END SALES */

 
/**
 * BUY
 */
const Suppliers = loadable(() => import("./pages/Buy/Suppliers"),{
    fallback:<Loading />
});


// CONTACT
const ContactListPage = loadable(() => import("./pages/Contacts/ListPage"),{
    fallback:<Loading />
});

const ContactFormPage = loadable(() => import("./pages/Contacts/FormPage"),{
    fallback:<Loading />
});


// END CONTACT


/** VOUCHER */
const VoucherSales = loadable(() => import("./pages/Voucher/Sales"),{
    fallback:<Loading />
});
const VoucherBuy = loadable(() => import("./pages/Voucher/Buy"),{
    fallback:<Loading />
});


// OVERVIEW VOUCHER ADVANCE 
const VoucherAdvanced = loadable(() => import("./pages/Voucher/Advanced/View"),{
    fallback:<Loading />
});

// VOUCHER ADVANCE ACTION : ADD - EDIT 
const VoucherAdvanceForm = loadable(()=> import("./pages/Voucher/Advanced/FormPage"),{
    fallback:<Loading />
})



const VoucherVat = loadable(() => import("./pages/Voucher/Vat"),{
    fallback:<Loading />
});
/** END VOUCHER */


// BANK 

// END BANK 

// SALARY 
const SalaryPage = loadable(()=> import("./pages/Salary"),{
    fallback:<Loading />
})

// REPORT PAGES 
const GeneralLedger = loadable(()=> import("./pages/Report/GeneralLedger"),{
    fallback:<Loading />
})
const SaldoBalance = loadable(()=> import("./pages/Report/SaldoBalance"),{
    fallback:<Loading />
})

// END REPORT PAGES


// OVER VIEW 
    // TRANSACTION PAGE 
    const TransactionView = loadable(()=> import("./pages/OverView/Transaction/View"),{ fallback:<Loading />});
    const TransactionDetail = loadable(()=> import("./pages/OverView/Transaction/Detail"),{ fallback:<Loading />});


    const AltinView = loadable(()=> import("./pages/OverView/Altinn"),{fallback:<Loading />});
    const AssetsView = loadable(()=> import("./pages/OverView/Assets"),{fallback:<Loading />});
    const ContactView = loadable(()=> import("./pages/OverView/Contact"),{fallback:<Loading />});




// END OVERVIEW

const Settings = loadable(() => import("./pages/Settings"),{
    fallback:<Loading />
});

//const SettingGeneral = loadable(()=> import('./pages/Settings/General')) ; 


// SETUP ROUTES
export const routes = [
    { path: "/dashboard", name: "Dashboard", component: Dashboard },
    
    { path: "/sales/invoice", name: "Oryton Invoice", component: Invoice },
    { path: "/sales/over-view", name: "Over View", component: OverView },
    { path: "/sales/sale-reports", name: "Over View", component: Reports },
    { path: "/sales/other-sales", name: "Over View", component: OtherSales },
    
    
    { path: "/buy/suppliers", name: "Buy", component: Suppliers },
    { path: "/contacts/list", name: "Buy", component: ContactListPage },
    { path: "/contacts/form", name: "Buy", component: ContactFormPage },
    

    // VOUCHER 
    { path: "/voucher/advanced/:form", name: "", component: VoucherAdvanceForm },
    { path: "/voucher/advanced", name: "", component: VoucherAdvanced },
    

    { path: "/voucher/sales", name: "", component: VoucherSales },
    { path: "/voucher/buy", name: "", component: VoucherBuy },

    
    { path: "/voucher/vat", name: "", component: VoucherVat },

    // END VOUCHER

    // BILAGSKY
    { path: "/bilagsky/verify-list", name: "", component: loadable(()=> import("./pages/Bilagsky/VerifyList"),{fallback:<Loading />} ) },
    { path: "/bilagsky/untreatd", name: "", component: loadable(()=> import("./pages/Bilagsky/Untreatd"),{fallback:<Loading />} ) },
    { path: "/bilagsky/archive", name: "", component: loadable(()=> import("./pages/Bilagsky/Archive"),{fallback:<Loading />} ) },
    { path: "/bilagsky/missing", name: "", component: loadable(()=> import("./pages/Bilagsky/Missing"),{fallback:<Loading />} ) },
    { path: "/bilagsky/remittance", name: "", component: loadable(()=> import("./pages/Bilagsky/Remittance"),{fallback:<Loading />} ) },
    // END BILAGSKY

    // BANK 
    {path:"/bank/bank-accounts", name:"", component: loadable(()=> import("./pages/Bank/BankAccount"),{ fallback:<Loading />}) },
    {path:"/bank/payment-list", name:"", component: loadable(()=> import("./pages/Bank/PaymentList"),{ fallback:<Loading />}) },
    {path:"/bank/statement", name:"", component: loadable(()=> import("./pages/Bank/Statement"),{ fallback:<Loading />}) },
    
    // SALARY 
    {path:"/salary/employee-accounts",name:"", component: loadable(()=> import("./pages/Salary/EmployeeAccount"),{fallback:<Loading />}) },
    {path:"/salary/report",name:"", component: loadable(()=> import("./pages/Salary/Report"),{fallback:<Loading />}) },
    

    // REPORT PAGE
    { path: "/report/general-ledger", name:"", component: GeneralLedger},
    { path: "/report/saldo-balance", name:"", component: SaldoBalance},
    
    // END REPORT PAGE
    

    // OVERVIEW
    {path:"/overview/transaction/:id", name:"", component:TransactionDetail},
    {path:"/overview/transaction", name:"", component:TransactionView},
    {path:"/overview/altinn", name:"", component:AltinView},
    {path:"/overview/assets", name:"", component:AssetsView},
    {path:"/overview/contacts", name:"", component:ContactView},
    
    // END OVERVIEW
    
    
    { path: "/settings", name: "Settings", component: Settings },
    


];


