import _ from 'lodash';
import APP_CONFIG from '../config/app.json';
const { nav } = APP_CONFIG;

const travalsal = _.memoize((NAV = [], curURL) => {
    if (NAV.length > 0) {
        const curRow = NAV.shift();
        let found;

        if (curRow.path === curURL) return curRow;
        if (!curRow.subs) return travalsal(NAV, curURL);

        found = curRow.subs.find((item) => item.path === curURL);
        return !found ? travalsal(NAV, curURL) : found;
    }

    return;
});

const getCurrentRoute = () => {
    let curURL = window.location.href; //location.pathname.replace("/" + company, "");
    curURL = '/' + curURL.split('/').slice(4, 6).join('/');
    curURL = curURL.indexOf('?') > -1 ? curURL.split('?')[0] : curURL;
    const DATA = _.cloneDeep([...nav]);

    return travalsal(DATA, curURL);
};

export default getCurrentRoute;
