import { Helper } from './help';
import { idNumCheck } from '../how';
import moment from 'moment';

/**
 *
 * @param {number} num
 * @param {strong} preNum
 * @returns {number} age
 */
const doCalculateAgeByIdNum = (num, preNum = '19') => {
    if (num.length < 6) return;
    else {
        try {
            const resCheckIdNum = idNumCheck(num);

            if (resCheckIdNum && resCheckIdNum.dob) {
                console.log(resCheckIdNum.dob);

                const dob = moment(resCheckIdNum.dob).format('YYYY-MM-DD');
                console.log(dob);
                return Helper.calculateAge(dob);
            }
        } catch (err) {}
    }
};

export default doCalculateAgeByIdNum;
