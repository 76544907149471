import axios from 'axios';

const BASE = 'https://api-account.oryton.vn'; //process.env.NODE_ENV === 'development' ? 'https://api-account.oryton.vn' : 'https://api-account.oryton.no';

console.log(BASE);
const myType = {
    isSuccess: false,
    msg: '', // MESSGAE IF BUGS
    data: {} // USER INFO
};

export const doLoginOryton = (username = '', password = '') => {
    const ret = { ...myType };

    return new Promise((resolve, reject) => {
        const url = BASE + '/api/login';

        axios
            .post(url, {
                username,
                password
            })
            .then((res) => {
                const { data } = res;
                resolve(Object.assign(ret, data));
            })
            .catch((err) => {
                resolve({ ...ret, msg: err });
            });
    });
};
